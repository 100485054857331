/* ========================================

    common
        共通に使えるパーツ

    INDEX
        - wrapper

======================================== */

/* ========================================
    wrapper
======================================== */
.main {
    position: relative;

    &__inner {
        position: relative;
        max-width: 1200px;
        margin: 0 auto;
    }

    &__logo {
        position: absolute;
        max-width: 113px;
        width: 100%;
        top: 30px;
        @include mq-mb {
            width: 90px;
            display: block;
            top: 20px;
            left: 20px;
        }
        @media screen and (max-width: 1024px) {
            position: static;
        }
    }

    .mb-site-name {
        @include Vadodara;
        top: 3.5%;
        right: 20px;
        text-align: right;
        color: white;
        font-size: 1.2rem;
        line-height: 1.3;
        position: static;
        margin-top: auto;
    }
}

.outer {
    background: url($IMG+'common/common_bg_white.png') $C-BLACK repeat;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transition: 2s;

    @media screen and (min-width: 769px) and (max-width: 1350px) {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        min-width: 1200px;
    }
}

.container {
    position: relative;
    z-index: 2;
    padding-top: 127px;
    min-height: 100vh;
    padding-bottom: 360px;
    box-sizing: border-box;
    @include mq-header-menu {
        padding-top: 80px;
    }
    @include mq-mb {
        padding-top: 0;
        padding-bottom: 230px;
    }
}

// 本当に全ての外側
.wrapper {
    position: relative;
    background: url($IMG+'common/common_bg.png') $C-BLACK repeat;
    background-attachment: fixed;
    transition: transform 0.3s, box-shadow 0.3s;
    z-index: 2;
    overflow: hidden;

    &.is-menu-open {
        transform: translate(-188px, 70px);
        box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.2);
    }
}

.glitch {
    &-bg {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 1;
    }
    &-img {
        position: absolute;
        width: 100vw;
        height: 100vh;
        max-width: 100%;
        top: 0;
        left: 0;
        background-size: 100% auto;
    }
}

.contents {
    @include mq-pc {
        margin-top: 100px;
    }
    @include mq-mb {
        padding-top: 50px;
        margin: 0 20px;
    }
}

.contact {
    @include Vadodara;
    color: $C_WHITE;
    border: solid 1px $C_WHITE;
    padding: 3px 60px;
}



.fancybox-slide {
    padding: 20px;
    max-width: 840px;
    max-height: 640px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include mq-mb {
        padding: 50px 20px;
    }
}

.fancybox-toolbar {
    @include mq-pc {
        top: 30px;
        right: 30px;
    }
    @include mq-mb {
        top: 10px;
        right: 10px;
    }
}

.fancybox-button--close {
    background: none;
    padding: 0;
}


@keyframes loading-bg{
	0% {
		left: 0;
		width: 0;
	}
	40% {
		left: 0;
		width: 100%;
	}
	60% {
		left: 0;
		width: 100%;
	}
	100% {
		left: 100%;
		width: 0;
	}
}

.js-loading {
    display: none;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 9000;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    animation: loading-bg 1.2s ease-in-out 0s 1 normal forwards;
}
.js-color-oosaka {
    background: #f36a00;
}
.js-color-nagoya {
    background: #6b4095;
}
.js-color-shibuya {
    background: #FFC600;
}
.js-color-shinjuku {
    background: #888B8D;
}
.js-color-asakusa {
    background: #ff856b;
}
.js-color-ikebukuro {
    background: #D50032;
}
.js-color-yokohama {
    background: #10069F;
}
.js-color-akabane {
    background: #80E0A7;
}

.glitch-img {
    width: 100%;
}

.sub-menu {
    &:after {
        content: '';
        position: absolute;
        top: -10%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px;
        height: 25px;
    }
}

.sp-titles {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 0;
    margin: 0 20px;
}

.blank-img {
    z-index: 999!important;
}
/* ========================================

    nav
        ナビゲーションのパーツ

    INDEX
        - global
        - trigger
        - sub
        - pnkz
        - members menu

======================================== */

/* ========================================
    global
======================================== */
.global-menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    transform: translateX(110vw);
    text-align: center;
    transition: transform 0.3s;
    z-index: 3;
    background: rgba(black, 0.8);
    overflow-y: scroll;

    &.is-menu-open {
        transform: translateX(0);
    }

    &__title {
        @include Vadodara;
        color: rgba(#85878c, 0.8);
        font-size: 20.75px;
        line-height: 1;
        padding: 3px 0 4px;
        @include mq-iphone5 {
            font-size: 17px;
        }
    }
    &-list {
        width: 280px;
        margin: 63px auto 0;
        &__item {
            @include Vadodara;
            font-size: 26px;
            a {
                color: white;
                display: block;
                padding: 8px;
            }
            span {
                font-size: 12px;
                margin-top: -10px;
                display: block;
            }
            .ghost-btn.ghost-btn--min {
                min-width: 120px;
                padding: 4px 7px;
                border: 1px solid white;
                margin: 27px auto 0;
            }

            &.archive {
                color: white;
                display: block;
                padding: 8px;
                .js-accordion-title {
                    position: relative;
                    &:before {
                        content: '';
                        background: url("../images/www/top/arrow.png") no-repeat;
                        transition: transform 0.3s ease-out;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background-size: 100%;
                        position: absolute;
                        top: 45.5%;
                        right: -11%;
                    }
                    &.js-open {
                        &:before {
                            transition: transform 0.3s ease-out;
                            transform: rotate(180deg);
                            top: 20%;
                        }
                    }
                }

                .sub-menu {
                    &__item {
                        a {
                            padding: 0;
                            font-size: 1.4rem;
                            color: white;
                            line-height: 1;
                            padding: 10px;
                            border-bottom: solid 1px white;

                            span {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
    &__sns {
        margin-top: 35px;

        &-item {
            font-size: 24px;
            color: white;
            padding: 0 20px;
        }
    }
}


/* ========================================
    trigger
======================================== */
// 以下から持ってきたハンバーガーメニュー
// https://www.nxworld.net/tips/12-css-hamburger-menu-active-effect.html
%trigger-base {
    display: inline-block;
    transition: all 0.2s;
    box-sizing: border-box;
}

.trigger-menu {
    @extend %trigger-base;
    $width_size: 60px;
    $height_size: 60px;
    $border_size: 1px;
    $padding_size: 10px;
    $middle_position: #{($height_size / 2) - ($border_size / 2) - ($padding_size)};
    width: $width_size;
    height: $height_size;
    background: black;
    border: 1px solid white;
    position: fixed;
    pointer-events: inherit;
    right: 27.5px;
    top: 20px;
    z-index: 1000 !important;

    @include mq-header-menu (false) {
        display: none;
    }

    @include mq-mb {
        top: 85%;
    }

    &__inner {
        position: relative;
        width: 22px;
        height: 17px;
        margin: 21px auto 0;
    }

    span {
        @extend %trigger-base;
        position: absolute;
        right: 0;
        width: 100%;
        height: $border_size;
        background-color: white;
        transform-origin: 100% 0;

        &:nth-of-type(1) {
            top: 0;
            width: 22px;
        }

        &:nth-of-type(2) {
            top: 7.5px;
            width: 22px;
        }

        &:nth-of-type(3) {
            bottom: 0;
            width: 22px;
        }
    }

    &:not(.trigger-menu--close) {
        &.is-menu-open {

            .trigger-menu__inner {
                width: 16px;
            }

            span {
                &:nth-of-type(1) {
                    transform: translateY(16px) rotate(45deg);
                }

                &:nth-of-type(2) {
                    opacity: 0;
                }

                &:nth-of-type(3) {
                    transform: translateY(-16px) rotate(-45deg);
                }
            }
        }
    }
    &--close {
        pointer-events: none;
        transform: rotate(-17deg);
        span {
            transform-origin: 100% 0;
            transition-delay: 0;
            &:nth-of-type(1) {
                transform: scale(0) rotate(59deg) translate(6px, -5px);
                transform-origin: 0 0;
            }
            &:nth-of-type(2) {
                transform: scale(0) rotate(-34deg) translate(-7px, -7px);
            }
            &:nth-of-type(3) {
                transform: scale(0) rotate(-34deg) translate(-1px, -6px);;
            }
        }
        &.is-menu-open {
            pointer-events: inherit;
            span {
                &:nth-of-type(1) {
                    transform: scale(1) rotate(59deg) translate(6px, -5px);
                    transition-delay: 0.4s;
                }

                &:nth-of-type(2) {
                    transform: scale(1) rotate(-34deg) translate(-7px, -7px);
                    transition-delay: 0.4s;
                }

                &:nth-of-type(3) {
                    transform: scale(1) rotate(-34deg) translate(-1px, -6px);
                    transition-delay: 0.4s;
                }
            }
        }
    }
}

/* ========================================
    sub
======================================== */
.sub-menu {

}


/* ========================================
    pnkz
======================================== */
.pnkz {

}

/* ========================================
    members menu
======================================== */
.members-menu {

}
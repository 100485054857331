@charset "UTF-8";
/* ========================================
    common
        サイト全体の設定、定義など
======================================== */
/* YUI 3.5.0 reset.css (http://developer.yahoo.com/yui/3/cssreset/) - https://cssreset.com/ */
html {
  color: #000;
  background: #FFF;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

input, textarea, select {
  *font-size: 100%;
}

legend {
  color: #000;
}

#yui3-css-stamp.cssreset {
  display: none;
}

/* ========================================
    
    base
        タグに直接効かせるスタイル

    INDEX
        - common
        - anti-aliasing
        - form

======================================== */
/* ========================================
    common
======================================== */
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  font-size: 62.5%;
  background-size: 160px;
  min-height: 100%;
  background: black;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
}

body.is-menu-open {
  overflow: hidden;
  background: none;
}

img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

a {
  outline: none;
  text-decoration: none;
}

*:focus {
  outline: none;
}

/* ========================================
    anti-aliasing
======================================== */
body,
html {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  body,
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

/* ========================================
    form
======================================== */
@media screen and (max-width: 768px) {
  input, textarea, select {
    font-size: 16px;
  }
}

/* ========================================
    
    parts
        付けるだけで動くスタイルのパーツ

    INDEX
        - element
        - layout
        - font
        - color
        - margin, padding
        - border

======================================== */
/* ========================================
    element
======================================== */
@media not screen and (min-width: 1081px) {
  .xl-elem {
    display: none !important;
  }
}

.xl-elem--inline {
  display: inline;
}

@media not screen and (min-width: 1081px) {
  .xl-elem--inline {
    display: none !important;
  }
}

@media not screen and (min-width: 769px) and (max-width: 1080px) {
  .l-elem {
    display: none !important;
  }
}

.l-elem--inline {
  display: inline;
}

@media not screen and (min-width: 769px) and (max-width: 1080px) {
  .l-elem--inline {
    display: none !important;
  }
}

@media not screen and (min-width: 481px) and (max-width: 768px) {
  .m-elem {
    display: none !important;
  }
}

.m-elem--inline {
  display: inline;
}

@media not screen and (min-width: 481px) and (max-width: 768px) {
  .m-elem--inline {
    display: none !important;
  }
}

@media not screen and (max-width: 480px) {
  .s-elem {
    display: none !important;
  }
}

.s-elem--inline {
  display: inline;
}

@media not screen and (max-width: 480px) {
  .s-elem--inline {
    display: none !important;
  }
}

@media not screen and (max-width: 768px) {
  .mb-elem {
    display: none !important;
  }
}

.mb-elem--inline {
  display: inline;
}

@media not screen and (max-width: 768px) {
  .mb-elem--inline {
    display: none !important;
  }
}

@media not screen and (min-width: 769px) {
  .pc-elem {
    display: none !important;
  }
}

.pc-elem--inline {
  display: inline;
}

@media not screen and (min-width: 769px) {
  .pc-elem--inline {
    display: none !important;
  }
}

/* ========================================
    layout
======================================== */
.al-c {
  text-align: center !important;
}

.al-r {
  text-align: right !important;
}

.al-l {
  text-align: left !important;
}

.fr {
  float: right !important;
}

.fl {
  float: left !important;
}

.cf:after {
  content: "";
  display: table;
  clear: both;
}

/* ========================================
    font
======================================== */
.x-small {
  font-size: 0.6em;
}

.small {
  font-size: 0.8em;
}

.large {
  font-size: 1.2em;
}

.x-large {
  font-size: 1.4em;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.link {
  color: red;
}

/* ========================================
    color
======================================== */
.c-white {
  color: white !important;
}

.c-gray {
  color: gray !important;
}

.c-black {
  color: black !important;
}

.c-red {
  color: red !important;
}

.c-green {
  color: green !important;
}

.c-blue {
  color: blue !important;
}

.c-yellow {
  color: yellow !important;
}

.c-pink {
  color: pink !important;
}

.c-purple {
  color: purple !important;
}

/* ========================================
    margin, padding
======================================== */
.mt-0 {
  margin-top: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.mt-105 {
  margin-top: 105px !important;
}

.mr-105 {
  margin-right: 105px !important;
}

.mb-105 {
  margin-bottom: 105px !important;
}

.ml-105 {
  margin-left: 105px !important;
}

.pt-105 {
  padding-top: 105px !important;
}

.pr-105 {
  padding-right: 105px !important;
}

.pb-105 {
  padding-bottom: 105px !important;
}

.pl-105 {
  padding-left: 105px !important;
}

.mt-110 {
  margin-top: 110px !important;
}

.mr-110 {
  margin-right: 110px !important;
}

.mb-110 {
  margin-bottom: 110px !important;
}

.ml-110 {
  margin-left: 110px !important;
}

.pt-110 {
  padding-top: 110px !important;
}

.pr-110 {
  padding-right: 110px !important;
}

.pb-110 {
  padding-bottom: 110px !important;
}

.pl-110 {
  padding-left: 110px !important;
}

.mt-115 {
  margin-top: 115px !important;
}

.mr-115 {
  margin-right: 115px !important;
}

.mb-115 {
  margin-bottom: 115px !important;
}

.ml-115 {
  margin-left: 115px !important;
}

.pt-115 {
  padding-top: 115px !important;
}

.pr-115 {
  padding-right: 115px !important;
}

.pb-115 {
  padding-bottom: 115px !important;
}

.pl-115 {
  padding-left: 115px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pr-120 {
  padding-right: 120px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pl-120 {
  padding-left: 120px !important;
}

/* ========================================
    border
======================================== */
hr.solid {
  border: none;
  border-top: 1px solid black;
}

hr.dot {
  border: none;
  border-top: 1px dotted black;
}

.banner-link {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  border: 4px solid white;
  color: white;
}

.banner-link:hover {
  background: white;
  color: black;
}

/* ========================================
    modules
        外部プラグインなどで使うもの
======================================== */
/* ========================================
    components
        共通で使うパーツを書くスタイル
======================================== */
/* ========================================

    common
        共通に使えるパーツ

    INDEX
        - wrapper

======================================== */
/* ========================================
    wrapper
======================================== */
.main {
  position: relative;
}

.main__inner {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.main__logo {
  position: absolute;
  max-width: 113px;
  width: 100%;
  top: 30px;
}

@media screen and (max-width: 768px) {
  .main__logo {
    width: 90px;
    display: block;
    top: 20px;
    left: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .main__logo {
    position: static;
  }
}

.main .mb-site-name {
  font-family: 'Hind Vadodara', sans-serif;
  top: 3.5%;
  right: 20px;
  text-align: right;
  color: white;
  font-size: 1.2rem;
  line-height: 1.3;
  position: static;
  margin-top: auto;
}

.outer {
  background: url("/images/common/common_bg_white.png") black repeat;
  position: relative;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: 2s;
  transition: 2s;
}

@media screen and (min-width: 769px) and (max-width: 1350px) {
  .outer {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-width: 1200px;
  }
}

.container {
  position: relative;
  z-index: 2;
  padding-top: 127px;
  min-height: 100vh;
  padding-bottom: 360px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .container {
    padding-top: 80px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 0;
    padding-bottom: 230px;
  }
}

.wrapper {
  position: relative;
  background: url("/images/common/common_bg.png") black repeat;
  background-attachment: fixed;
  -webkit-transition: -webkit-transform 0.3s, -webkit-box-shadow 0.3s;
  transition: -webkit-transform 0.3s, -webkit-box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s, -webkit-box-shadow 0.3s;
  z-index: 2;
  overflow: hidden;
}

.wrapper.is-menu-open {
  -webkit-transform: translate(-188px, 70px);
          transform: translate(-188px, 70px);
  -webkit-box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.2);
}

.glitch-bg {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}

.glitch-img {
  position: absolute;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  top: 0;
  left: 0;
  background-size: 100% auto;
}

@media screen and (min-width: 769px) {
  .contents {
    margin-top: 100px;
  }
}

@media screen and (max-width: 768px) {
  .contents {
    padding-top: 50px;
    margin: 0 20px;
  }
}

.contact {
  font-family: 'Hind Vadodara', sans-serif;
  color: white;
  border: solid 1px white;
  padding: 3px 60px;
}

.fancybox-slide {
  padding: 20px;
  max-width: 840px;
  max-height: 640px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  .fancybox-slide {
    padding: 50px 20px;
  }
}

@media screen and (min-width: 769px) {
  .fancybox-toolbar {
    top: 30px;
    right: 30px;
  }
}

@media screen and (max-width: 768px) {
  .fancybox-toolbar {
    top: 10px;
    right: 10px;
  }
}

.fancybox-button--close {
  background: none;
  padding: 0;
}

@-webkit-keyframes loading-bg {
  0% {
    left: 0;
    width: 0;
  }
  40% {
    left: 0;
    width: 100%;
  }
  60% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}

@keyframes loading-bg {
  0% {
    left: 0;
    width: 0;
  }
  40% {
    left: 0;
    width: 100%;
  }
  60% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}

.js-loading {
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9000;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-animation: loading-bg 1.2s ease-in-out 0s 1 normal forwards;
          animation: loading-bg 1.2s ease-in-out 0s 1 normal forwards;
}

.js-color-oosaka {
  background: #f36a00;
}

.js-color-nagoya {
  background: #6b4095;
}

.js-color-shibuya {
  background: #FFC600;
}

.js-color-shinjuku {
  background: #888B8D;
}

.js-color-asakusa {
  background: #ff856b;
}

.js-color-ikebukuro {
  background: #D50032;
}

.js-color-yokohama {
  background: #10069F;
}

.js-color-akabane {
  background: #80E0A7;
}

.glitch-img {
  width: 100%;
}

.sub-menu:after {
  content: '';
  position: absolute;
  top: -10%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 250px;
  height: 25px;
}

.sp-titles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px 0 0;
  margin: 0 20px;
}

.blank-img {
  z-index: 999 !important;
}

/* ========================================
    
    header
        ヘッダーのパーツ

    INDEX
        - common

======================================== */
/* ========================================
    common
======================================== */
.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
}

@media screen and (max-width: 1024px) {
  .header {
    display: none;
  }
}

.header__inner {
  max-width: 1540px !important;
  margin: 0 auto;
}

@media screen and (max-width: 1250px) {
  .header__inner {
    padding-left: 10px;
  }
}

.header__nav {
  font-family: 'Hind Vadodara', sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 53px;
  padding: 43px 0;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 1.8rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header__nav-item {
  position: relative;
  cursor: pointer;
  text-align: center;
}

.header__nav-item:not(:nth-child(n+5)):before {
  position: absolute;
  content: "";
  display: inline-block;
  background: white;
  width: 1px;
  height: 80%;
  top: 7px;
  right: 0;
}

.header__nav-item:not(:last-child) {
  padding: 0 15px;
}

.header__nav-item:last-child {
  padding-left: 10px;
}

.header__nav-item .nav-btn {
  display: block;
  color: white;
}

.header__nav-item .nav-btn:hover {
  color: black;
  background: white;
}

.header__nav-item .nav-btn-jp {
  display: block;
  font-size: 0.6em;
  margin-top: -5px;
}

.header__nav-item.archive {
  position: relative;
}

.header__nav-item.archive .sub-menu {
  display: none;
  position: absolute;
  top: 60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 250px;
  z-index: 9999;
  background: white;
  text-align: center;
}

.header__nav-item.archive .sub-menu a {
  color: black !important;
  display: block;
  padding: 11px 0;
}

.header__nav-item.archive .sub-menu:before {
  content: "";
  position: absolute;
  top: -30px;
  left: 50%;
  margin-left: -15px;
  border: 15px solid transparent;
  border-bottom: 15px solid white;
}

.header__nav-item.archive .sub-menu__item:not(:last-child) {
  border-bottom: solid 1px black;
}

.header__nav-item.archive:hover .sub-menu {
  display: block;
}

/* ========================================

    footer
        フッターのパーツ

    INDEX
        - common

======================================== */
/* ========================================
    common
======================================== */
.footer {
  background: black;
  color: white;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footer .sns {
  font-family: 'Hind Vadodara', sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 70px 0 28px;
  max-width: 215px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .footer .sns {
    padding: 33px 0 14px;
    max-width: 160px;
  }
}

.footer .sns__text {
  position: relative;
  font-size: 2.6rem;
  margin-right: 40px;
}

@media screen and (max-width: 768px) {
  .footer .sns__text {
    font-size: 1.6rem;
  }
}

.footer .sns__text::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: -33px;
  margin: auto;
  width: 20px;
  height: 1px;
  background-color: white;
}

.footer .sns__icon {
  color: white;
  font-size: 26px;
  margin: 0 5px;
}

@media screen and (max-width: 768px) {
  .footer .sns__icon {
    font-size: 17px;
  }
}

.footer .copy {
  padding-bottom: 135px;
  padding-top: 20px;
}

@media screen and (max-width: 768px) {
  .footer .copy {
    padding-top: 10px;
    padding-bottom: 100px;
    font-size: 10px;
  }
}

/* ========================================
    
    sns
        ソーシャルメディア系のスタイル

    INDEX
        - menu

======================================== */
/* ========================================
    menu
======================================== */
.sns-menu__item, .sns-menu__item--twitter, .sns-menu__item--instagram, .sns-menu__item--facebook, .sns-menu__item--youtube, .sns-menu__item--line, .sns-menu__item--google {
  display: inline-block;
}

/* ========================================

    nav
        ナビゲーションのパーツ

    INDEX
        - global
        - trigger
        - sub
        - pnkz
        - members menu

======================================== */
/* ========================================
    global
======================================== */
.global-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  -webkit-transform: translateX(110vw);
          transform: translateX(110vw);
  text-align: center;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: 3;
  background: rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
}

.global-menu.is-menu-open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.global-menu__title {
  font-family: 'Hind Vadodara', sans-serif;
  color: rgba(133, 135, 140, 0.8);
  font-size: 20.75px;
  line-height: 1;
  padding: 3px 0 4px;
}

@media screen and (max-width: 320px) {
  .global-menu__title {
    font-size: 17px;
  }
}

.global-menu-list {
  width: 280px;
  margin: 63px auto 0;
}

.global-menu-list__item {
  font-family: 'Hind Vadodara', sans-serif;
  font-size: 26px;
}

.global-menu-list__item a {
  color: white;
  display: block;
  padding: 8px;
}

.global-menu-list__item span {
  font-size: 12px;
  margin-top: -10px;
  display: block;
}

.global-menu-list__item .ghost-btn.ghost-btn--min {
  min-width: 120px;
  padding: 4px 7px;
  border: 1px solid white;
  margin: 27px auto 0;
}

.global-menu-list__item.archive {
  color: white;
  display: block;
  padding: 8px;
}

.global-menu-list__item.archive .js-accordion-title {
  position: relative;
}

.global-menu-list__item.archive .js-accordion-title:before {
  content: '';
  background: url("../images/www/top/arrow.png") no-repeat;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-size: 100%;
  position: absolute;
  top: 45.5%;
  right: -11%;
}

.global-menu-list__item.archive .js-accordion-title.js-open:before {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  top: 20%;
}

.global-menu-list__item.archive .sub-menu__item a {
  padding: 0;
  font-size: 1.2rem;
  color: white;
}

.global-menu__sns {
  margin-top: 35px;
}

.global-menu__sns-item {
  font-size: 24px;
  color: white;
  padding: 0 20px;
}

/* ========================================
    trigger
======================================== */
.trigger-menu, .trigger-menu span {
  display: inline-block;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.trigger-menu {
  width: 60px;
  height: 60px;
  background: black;
  border: 1px solid white;
  position: fixed;
  pointer-events: inherit;
  right: 27.5px;
  top: 20px;
  z-index: 1000 !important;
}

@media not screen and (max-width: 1024px) {
  .trigger-menu {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .trigger-menu {
    top: 85%;
  }
}

.trigger-menu__inner {
  position: relative;
  width: 22px;
  height: 17px;
  margin: 21px auto 0;
}

.trigger-menu span {
  position: absolute;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: white;
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
}

.trigger-menu span:nth-of-type(1) {
  top: 0;
  width: 22px;
}

.trigger-menu span:nth-of-type(2) {
  top: 7.5px;
  width: 22px;
}

.trigger-menu span:nth-of-type(3) {
  bottom: 0;
  width: 22px;
}

.trigger-menu:not(.trigger-menu--close).is-menu-open .trigger-menu__inner {
  width: 16px;
}

.trigger-menu:not(.trigger-menu--close).is-menu-open span:nth-of-type(1) {
  -webkit-transform: translateY(16px) rotate(45deg);
          transform: translateY(16px) rotate(45deg);
}

.trigger-menu:not(.trigger-menu--close).is-menu-open span:nth-of-type(2) {
  opacity: 0;
}

.trigger-menu:not(.trigger-menu--close).is-menu-open span:nth-of-type(3) {
  -webkit-transform: translateY(-16px) rotate(-45deg);
          transform: translateY(-16px) rotate(-45deg);
}

.trigger-menu--close {
  pointer-events: none;
  -webkit-transform: rotate(-17deg);
          transform: rotate(-17deg);
}

.trigger-menu--close span {
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

.trigger-menu--close span:nth-of-type(1) {
  -webkit-transform: scale(0) rotate(59deg) translate(6px, -5px);
          transform: scale(0) rotate(59deg) translate(6px, -5px);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}

.trigger-menu--close span:nth-of-type(2) {
  -webkit-transform: scale(0) rotate(-34deg) translate(-7px, -7px);
          transform: scale(0) rotate(-34deg) translate(-7px, -7px);
}

.trigger-menu--close span:nth-of-type(3) {
  -webkit-transform: scale(0) rotate(-34deg) translate(-1px, -6px);
          transform: scale(0) rotate(-34deg) translate(-1px, -6px);
}

.trigger-menu--close.is-menu-open {
  pointer-events: inherit;
}

.trigger-menu--close.is-menu-open span:nth-of-type(1) {
  -webkit-transform: scale(1) rotate(59deg) translate(6px, -5px);
          transform: scale(1) rotate(59deg) translate(6px, -5px);
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}

.trigger-menu--close.is-menu-open span:nth-of-type(2) {
  -webkit-transform: scale(1) rotate(-34deg) translate(-7px, -7px);
          transform: scale(1) rotate(-34deg) translate(-7px, -7px);
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}

.trigger-menu--close.is-menu-open span:nth-of-type(3) {
  -webkit-transform: scale(1) rotate(-34deg) translate(-1px, -6px);
          transform: scale(1) rotate(-34deg) translate(-1px, -6px);
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}

/* ========================================
    sub
======================================== */
/* ========================================
    pnkz
======================================== */
/* ========================================
    members menu
======================================== */
/* ========================================

    support
        サポートのパーツ

    INDEX
        - area
        - list

======================================== */
/* ========================================
    area
======================================== */
/* ========================================
    list
======================================== */
/* ========================================

    pager
        ページャーのパーツ

    INDEX
        - pager

======================================== */
/* ========================================
    pager
======================================== */
.pager .btn-back, .pager .btn-list, .pager .btn-next, .pager .btn-num__item {
  font-family: 'Hind Vadodara', sans-serif;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 10px 0;
  text-align: center;
  margin: 0 10px;
}

@media screen and (max-width: 768px) {
  .pager .btn-back, .pager .btn-list, .pager .btn-next, .pager .btn-num__item {
    padding: 0;
    font-size: 1.2rem;
    margin: 0 5px;
  }
}

.pager .btn-back:hover, .pager .btn-list:hover, .pager .btn-next:hover, .pager .btn-num__item:hover {
  color: black;
  background: white;
}

.pager {
  margin: 0 auto 150px;
  color: white;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media screen and (max-width: 768px) {
  .pager {
    margin: 0 auto 60px;
  }
}

.pager li a {
  color: inherit;
  padding: 10px 20px;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .pager li a {
    padding: 2px 10px;
  }
}

.pager .btn-back {
  margin-right: 10px;
  position: relative;
  padding-left: 20px;
}

@media screen and (max-width: 768px) {
  .pager .btn-back {
    margin-right: 5px;
    padding-left: 10px;
  }
}

.pager .btn-back:before {
  font-family: "Font Awesome 5 Pro";
  content: '\f053';
  font-weight: 400;
  padding-right: 10px;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .pager .btn-back:before {
    left: 5px;
  }
}

.pager .btn-list {
  margin: 0 5px;
}

.pager .btn-next {
  margin-left: 10px;
  position: relative;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .pager .btn-next {
    margin-left: 5px;
    padding-right: 10px;
  }
}

.pager .btn-next:after {
  font-family: "Font Awesome 5 Pro";
  content: '\f054';
  font-weight: 400;
  position: absolute;
}

.pager .btn-num__item.current {
  background: white;
  color: black !important;
}

.pager .disabled {
  pointer-events: none;
  opacity: 0.2;
}

/* ========================================

    title
        見出しのパーツ

    INDEX
        - section
        - main
        - sub

======================================== */
/* ========================================
    global
        大見出し
======================================== */
.section-title {
  color: white;
  font-family: "Hind Vadodara", sans-serif;
  font-weight: 700;
  font-size: 4.6vw;
  line-height: 1;
  letter-spacing: 0.04em;
  margin: 0 50px 80px 0;
  text-align: right;
  position: relative;
}

@media screen and (min-width: 1400px) {
  .section-title {
    font-size: 6.5rem;
    line-height: 1;
  }
}

@media screen and (min-width: 769px) and (max-width: 1080px) {
  .section-title {
    margin: 0 50px 40px 0;
    font-size: 4.5rem;
    line-height: 1;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .section-title {
    margin: 0 20px 20px 0;
    font-size: 3.8rem;
    line-height: 1;
  }
}

@media screen and (max-width: 480px) {
  .section-title {
    margin: 0 15px 20px 0;
    font-size: 3rem;
    line-height: 1;
  }
}

.section-title:after {
  content: attr(data-text) "";
  font-size: 7vw;
  line-height: 1;
  color: rgba(255, 255, 255, 0.1);
  position: absolute;
  right: -50px;
  bottom: 20px;
}

@media screen and (min-width: 1400px) {
  .section-title:after {
    font-size: 10rem;
    line-height: 1;
  }
}

@media screen and (min-width: 769px) and (max-width: 1080px) {
  .section-title:after {
    font-size: 6.5rem;
    line-height: 1;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .section-title:after {
    font-size: 5.5rem;
    line-height: 1;
    right: -20px;
    bottom: 10px;
  }
}

@media screen and (max-width: 480px) {
  .section-title:after {
    font-size: 5.5rem;
    line-height: 1;
    right: -15px;
    bottom: 10px;
  }
}

/* ========================================
    main
        中見出し
======================================== */
.main-title {
  color: white;
  font-family: "Hind Vadodara", sans-serif;
  font-weight: 700;
  font-size: 3.57vw;
  line-height: 1;
  padding-left: 50px;
  margin-bottom: 50px;
  position: relative;
}

@media screen and (min-width: 1400px) {
  .main-title {
    font-size: 5rem;
    line-height: 1;
  }
}

@media screen and (min-width: 769px) and (max-width: 1080px) {
  .main-title {
    font-size: 3.5rem;
    line-height: 1;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .main-title {
    font-size: 3.2rem;
    line-height: 1;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
  .main-title {
    font-size: 2.8rem;
    line-height: 1;
    padding-left: 15px;
    margin-bottom: 20px;
  }
}

.main-title:after {
  content: '';
  display: block;
  width: 50%;
  height: 1px;
  background: white;
  position: absolute;
  left: 0;
  bottom: -2px;
}

.main-title-jp {
  font-size: 2.86vw;
  line-height: 1;
}

@media screen and (min-width: 1400px) {
  .main-title-jp {
    font-size: 4rem;
    line-height: 1;
  }
}

@media screen and (min-width: 769px) and (max-width: 1080px) {
  .main-title-jp {
    font-size: 2.8rem;
    line-height: 1;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .main-title-jp {
    font-size: 2.5rem;
    line-height: 1;
  }
}

@media screen and (max-width: 480px) {
  .main-title-jp {
    font-size: 2.3rem;
    line-height: 1;
  }
}

.main-title-jp:after {
  width: 65%;
}

/* ========================================
    sub
        子見出し
======================================== */
.sub-title {
  font-family: 'Hind Vadodara', sans-serif;
  font-size: 6rem;
  color: white;
  position: relative;
}

@media screen and (min-width: 769px) {
  .sub-title {
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
  }
}

@media screen and (max-width: 768px) {
  .sub-title {
    font-size: 3rem;
    margin-bottom: 10px;
  }
}

.sub-title:after {
  content: '';
  display: block;
  position: absolute;
  background: white;
}

@media screen and (min-width: 769px) {
  .sub-title:after {
    height: 145px;
    width: 3px;
    top: 25%;
    left: 42%;
    margin-left: 6px;
  }
}

@media screen and (max-width: 768px) {
  .sub-title:after {
    top: 0;
    bottom: 0;
    margin: auto;
    right: -14px;
    width: 90px;
    height: 1px;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

/* ========================================

    btn
        ボタンのスタイル

    INDEX
        - class
        - normal
        - special

======================================== */
.btn-100, .btn-50, .btn-30, .btn-30--right, .btn-30--left {
  font-family: 'Hind Vadodara', sans-serif;
  font-size: 20px;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  text-align: center;
  width: 300px;
  margin: 50px auto;
  padding: 15px 0;
  text-decoration: none !important;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .btn-100, .btn-50, .btn-30, .btn-30--right, .btn-30--left {
    width: 300px;
    min-height: 43px;
    font-size: 18px;
  }
}

.btn-100:hover, .btn-50:hover, .btn-30:hover, .btn-30--right:hover, .btn-30--left:hover {
  color: black;
  background-color: white;
}

.btn-special-100, .btn-special-50 {
  background-color: red;
  color: white;
}

.btn-100, .btn-special-100 {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.btn-50, .btn-special-50 {
  display: inline-block;
  width: 49%;
}

.btn-30, .btn-30--right, .btn-30--left {
  display: block;
  width: 30%;
}

/* ========================================
    normal
        よく使うボタン
======================================== */
.btn-30, .btn-30--right, .btn-30--left {
  margin: auto;
}

.btn-30--right {
  margin-right: 0;
  margin-left: auto;
}

.btn-30--left {
  margin-right: auto;
  margin-left: 0;
}

/* ========================================
    special
        特別目立たせたいボタン
======================================== */
.ghost-btn {
  display: block;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 4px 0;
  text-align: center;
}

.ghost-btn--min {
  min-width: 158px;
}

.btn-more {
  font-family: 'Hind Vadodara', sans-serif;
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  text-align: center;
  width: 300px;
  min-height: 67px;
  margin: 50px auto;
}

@media screen and (max-width: 768px) {
  .btn-more {
    width: 300px;
    min-height: 43px;
    font-size: 18px;
  }
}

/* ========================================
  
    list
        リストのスタイル

    INDEX
        - text list
        - thumb list
        - circle list

======================================== */
.list {
  display: block;
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .list {
    margin-bottom: 40px;
  }
}

.list__item {
  word-break: break-word;
  font-size: 16px;
}

.list__item + .list__item {
  margin-top: 32px;
}

.list__link {
  color: white;
  display: block;
}

.list__date {
  font-family: 'Hind Vadodara', sans-serif;
  color: #b0a46a;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .list__date {
    font-size: 14px;
  }
}

.list__label {
  font-family: 'Hind Vadodara', sans-serif;
  font-size: 15px;
  border: 1px solid white;
  min-width: 110px;
  display: inline-block;
  text-align: center;
  line-height: 1.28;
  margin-left: 9px;
}

@media screen and (max-width: 768px) {
  .list__label {
    font-size: 11px;
    min-width: 83px;
  }
}

@media screen and (max-width: 768px) {
  .list__title {
    font-size: 12px;
  }
}

.list__title:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: white;
          text-decoration-color: white;
}

.list__title.none {
  pointer-events: none;
}

.category-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 47px;
  border: 1px solid white;
}

@media screen and (max-width: 768px) {
  .category-list {
    margin-bottom: 25px;
  }
}

.category-list__item {
  font-family: 'Hind Vadodara', sans-serif;
  width: 16.66667%;
  background-color: black;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 15px;
  cursor: pointer;
}

.category-list__item.current {
  background-color: white;
  color: black;
}

.category-list__item:not(.current) {
  border-left: 1px solid white;
}

@media screen and (max-width: 768px) {
  .category-list__item {
    width: 33.33333%;
    font-size: 11px;
    height: 30px;
  }
}

.category-list__item:hover {
  color: black;
  background: white;
}

.category-list__link {
  font-family: 'Hind Vadodara', sans-serif;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.category-list__link:hover {
  color: black;
}

@media screen and (max-width: 768px) {
  .list-none {
    text-align: center;
    margin-bottom: 40px;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.fade-enter-t0 {
  opacity: 1;
}

.fade2-enter {
  opacity: 0;
}

.fade2-enter-active {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.fade2-enter-to {
  opacity: 1;
}

/* ========================================

    movie
        動画関係のパーツ

    INDEX
        - youtube
        - milvi

======================================== */
/* ========================================
    youtube
======================================== */
.movie-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.movie-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/* ========================================
    milvi
======================================== */
.movie-container {
  position: relative;
  padding-top: 56.25%;
}

.eviry-screen {
  max-width: 100%;
  max-height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
}

.eviry-baseplayer {
  position: absolute !important;
  max-width: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}

.eviry-baseplayer,
.eviry-deck,
.miovip-controlpanel {
  margin: 0 auto !important;
  width: 100% !important;
}

/* ========================================

    post entry
        管理画面からの登録した本文の見た目調整用

======================================== */
.aem-post,
.post-entry {
  overflow: hidden;
  word-wrap: break-word;
  font-size: 1.4rem;
  line-height: 1.85;
  margin-top: 60px;
}

@media screen and (max-width: 768px) {
  .aem-post,
  .post-entry {
    margin-top: 45px;
  }
}

.aem-post strong,
.post-entry strong {
  font-weight: bold;
}

.aem-post img,
.post-entry img {
  max-width: 100%;
  height: auto !important;
}

.aem-post h1,
.post-entry h1 {
  font-size: 2em;
}

.aem-post h2,
.post-entry h2 {
  font-size: 1.8em;
}

.aem-post h3,
.post-entry h3 {
  font-size: 1.6em;
}

.aem-post h4,
.post-entry h4 {
  font-size: 1.4em;
}

.aem-post h5,
.post-entry h5 {
  font-size: 1.2em;
}

.aem-post h6,
.post-entry h6 {
  font-size: 1em;
}

.aem-post table,
.aem-post iframe,
.post-entry table,
.post-entry iframe {
  max-width: 100%;
}

.aem-post table,
.post-entry table {
  width: 100%;
  table-layout: fixed;
  word-break: break-word;
}

.aem-post a,
.post-entry a {
  color: white;
  text-decoration: underline;
}

.aem-post em,
.post-entry em {
  font-style: italic;
}

/* ========================================
    pages
        ページごとに独立するスタイル
======================================== */
/* ========================================

    index
        indexページのみで使うスタイル

    INDEX
        - main

======================================== */
/* ========================================
    main
======================================== */
.hero {
  max-width: 1540px;
  opacity: 0;
}

@media screen and (min-width: 769px) {
  .hero {
    position: relative;
    margin: 0 auto 60px;
  }
}

@media screen and (max-width: 768px) {
  .hero {
    margin: 0 auto 61px;
  }
}

@media screen and (min-width: 769px) {
  .hero__inner-logo {
    position: absolute;
    top: 0;
    left: 25px;
    width: 18%;
  }
}

@media screen and (max-width: 768px) {
  .hero__inner-logo {
    width: 100px;
    display: block;
    top: 0;
    left: 0;
  }
}

.hero__header--nav {
  width: 135px;
  font-size: 13px;
}

.hero__header--sns {
  font-size: 18px;
  color: white;
  padding: 0 7.5px;
}

@media screen and (min-width: 769px) {
  .hero__header--sns {
    display: block;
    font-size: 24px;
  }
  .hero__header--sns:hover {
    opacity: 0.8;
  }
}

.hero__header--sns-wrap {
  position: absolute;
  right: 10px;
}

.hero__animate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  margin-right: 54px;
}

@media screen and (max-width: 768px) {
  .hero__animate {
    display: block;
    margin: 0;
    padding-top: 16px;
  }
}

.hero__left {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .hero__left {
    margin: 18px 0 0 20px;
  }
}

.hero__right {
  position: relative;
  width: 77%;
  max-width: 1000px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .hero__right {
    width: 100%;
    display: block;
  }
}

.hero__kv--logo {
  position: absolute;
  bottom: -25px;
  left: 30px;
  z-index: 2;
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .hero__kv--logo {
    top: 555px;
    left: 0;
  }
}

.hero__side-text {
  font-family: 'Hind Vadodara', sans-serif;
  font-size: 1.04rem;
  display: block;
  bottom: 0;
  text-align: center;
  color: white;
}

@media screen and (min-width: 769px) {
  .hero__side-text {
    -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    line-height: 54px;
  }
}

@media screen and (max-width: 768px) {
  .hero__side-text {
    font-size: 2rem;
    padding: 0 17px;
    text-align: left;
    line-height: 1.2;
    margin-bottom: 15px;
  }
}

.hero__info {
  font-family: 'Hind Vadodara', sans-serif;
  text-align: right;
}

@media screen and (min-width: 769px) {
  .hero__info {
    margin-top: 104%;
    padding-right: 2.8rem;
  }
}

@media screen and (max-width: 1200px) {
  .hero__info {
    padding-right: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .hero__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .hero__info-text {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}

.hero__logo {
  width: 215px;
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .hero__logo {
    width: 100px;
    margin-bottom: auto;
  }
}

.hero__division-name {
  position: relative;
}

@media screen and (min-width: 769px) {
  .hero__division-name {
    margin-top: 21px;
    margin-bottom: 19px;
  }
}

@media screen and (max-width: 768px) {
  .hero__division-name {
    margin-bottom: 10px;
  }
}

.hero__division-name::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: -30px;
  width: 1000px;
  height: 1px;
}

@media screen and (max-width: 768px) {
  .hero__division-name::before {
    right: -20px;
    width: 100%;
  }
}

.hero__division-name h2 {
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 1;
  padding: 5px 0 23px 0;
}

@media screen and (max-width: 1505px) {
  .hero__division-name h2 {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 768px) {
  .hero__division-name h2 {
    line-height: 1;
    font-size: 1.98rem;
    padding: 0 0 15px 0;
  }
}

.hero__affiliation-division {
  font-size: 2rem;
  line-height: 1;
}

@media screen and (max-width: 1505px) {
  .hero__affiliation-division {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .hero__affiliation-division {
    font-size: 15px;
  }
}

.hero__member-name {
  display: block;
  line-height: 1.4;
  font-size: 1.6rem;
}

@media screen and (max-width: 1505px) {
  .hero__member-name {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 768px) {
  .hero__member-name {
    font-size: 12px;
  }
}

.hero.js-active {
  opacity: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.hero.division--oosaka {
  color: #f36a00;
}

.hero.division--oosaka .hero__division-name::before {
  background-color: #f36a00;
}

.hero.division--nagoya {
  color: #6b4095;
}

.hero.division--nagoya .hero__division-name::before {
  background-color: #6b4095;
}

.hero.division--shibuya {
  color: #FFC600;
}

.hero.division--shibuya .hero__division-name::before {
  background-color: #FFC600;
}

.hero.division--shinjuku {
  color: #888B8D;
}

.hero.division--shinjuku .hero__division-name::before {
  background-color: #888B8D;
}

.hero.division--asakusa {
  color: #ff856b;
}

.hero.division--asakusa .hero__division-name::before {
  background-color: #ff856b;
}

.hero.division--ikebukuro {
  color: #d50032;
}

.hero.division--ikebukuro .hero__division-name::before {
  background-color: #d50032;
}

.hero.division--yokohama {
  color: #1a09ff;
}

.hero.division--yokohama .hero__division-name::before {
  background-color: #1a09ff;
}

.hero.division--akabane {
  color: #80e0a7;
}

.hero.division--akabane .hero__division-name::before {
  background-color: #80e0a7;
}

.top-contents {
  max-width: 1400px;
  margin: 0 auto;
}

@media screen and (min-width: 769px) {
  .top-contents {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .top-contents {
    margin: 0 20px;
  }
}

.top-contents .news {
  color: white;
}

@media screen and (min-width: 769px) {
  .top-contents .news {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 800px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .top-contents .news .sub-title {
    display: inline-block;
  }
}

@media screen and (max-width: 768px) {
  .top-contents .news .sub-title:after {
    right: -14px;
    width: 90px;
  }
}

@media screen and (min-width: 769px) {
  .twitter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 450px;
  }
}

@media screen and (max-width: 768px) {
  .twitter .sub-title {
    display: inline-block;
  }
}

@media screen and (min-width: 769px) {
  .twitter .sub-title:after {
    top: 250px;
  }
}

.twitter__timeline {
  height: 700px;
}

@media screen and (max-width: 768px) {
  .twitter__timeline {
    height: 400px;
  }
}

.link-banner {
  max-width: 1200px;
}

@media screen and (min-width: 769px) {
  .link-banner {
    margin: 150px auto 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .link-banner {
    margin: 50px 20px;
    text-align: center;
  }
}

.link-banner img {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .link-banner img:nth-child(2) {
    margin: 0 30px;
  }
}

@media screen and (max-width: 768px) {
  .link-banner img {
    margin-top: 16px;
  }
}

/* ========================================
    banner
======================================== */
.banner {
  position: relative;
  overflow: hidden;
  height: 238px;
  margin-top: 140px;
}

@media screen and (max-width: 768px) {
  .banner {
    margin-top: 100px;
  }
}

.banner__wrap {
  height: 238px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.banner__slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.banner__slider-item {
  width: 337px;
  height: 238px;
}

.banner__slider-item:hover {
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .banner__slider-item {
    width: 205px;
  }
}

.banner__slider-img {
  width: 337px;
}

@media screen and (max-width: 768px) {
  .banner__slider-img {
    width: 205px;
  }
}

/* ========================================
    slider
======================================== */
@media screen and (max-width: 768px) {
  .slick-track {
    padding-bottom: 60px;
  }
}

.slick-slide {
  width: 337px !important;
}

@media screen and (max-width: 768px) {
  .slick-slide {
    width: 205px !important;
  }
}

.slick-slide img {
  width: 100%;
  margin: 0 auto;
}

/* ========================================
    NOTICE
======================================== */
.notice {
  max-width: 1540px;
  width: 100%;
  padding-left: 20px;
  padding-right: 54px;
  margin: 0 auto 70px;
}

@media screen and (max-width: 768px) {
  .notice {
    padding: 0 20px;
  }
}

.notice__wrap {
  max-width: 1200px;
  width: 100%;
  min-height: 50px;
  background: black;
  border: 1px solid white;
  margin-right: 0;
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .notice__wrap {
    min-height: 70px;
  }
}

.notice__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.notice__title {
  width: 180px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  font-family: "Hind Vadodara", sans-serif;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .notice__title {
    font-size: 13px;
    min-width: 70px;
  }
}

.notice__news {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px 29px;
  border-left: 1px solid white;
}

@media screen and (max-width: 768px) {
  .notice__news {
    display: block;
    padding: 18px 14px;
    line-height: 1em;
  }
}

.notice__news--date {
  color: #B0A46A;
  font-size: 18px;
  font-weight: bold;
  font-family: "Hind Vadodara", sans-serif;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .notice__news--date {
    font-size: 13px;
  }
}

.notice__news--link {
  font-size: 16px;
  color: white;
}

@media screen and (max-width: 768px) {
  .notice__news--link {
    font-size: 11px;
  }
}

/* ========================================
    swiper
======================================== */
.swiper-slide {
  padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .swiper-slide {
    padding-left: 40px;
    text-align: center;
  }
}

.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  border-radius: 0;
  background: white;
  margin-left: 10px !important;
}

@media screen and (max-width: 768px) {
  .swiper-pagination-bullet {
    display: block;
    margin-left: 15px !important;
    margin-bottom: 6px !important;
    width: 11px;
    height: 11px;
  }
}

.swiper-pagination-bullets {
  text-align: right;
  bottom: 0 !important;
}

@media screen and (max-width: 768px) {
  .swiper-pagination-bullets {
    top: 0;
    max-width: 40px;
  }
}

/* ========================================
    fade
======================================== */
.js-fade {
  position: absolute;
  top: 0;
  left: 0;
}

.nodisplay {
  position: relative;
  opacity: 1;
  z-index: 2;
  -webkit-transition: 0.4s 1s;
  transition: 0.4s 1s;
}

.nodisplay.js-active {
  opacity: 0;
  -webkit-transition: 1s;
  transition: 1s;
}

.js-fade-wrap {
  position: relative;
  overflow: hidden;
}

.js-fade-logo {
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.js-fade-logo.js-active {
  opacity: 1;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

/* ========================================
    タイピングアニメーション
======================================== */
.u-split-span {
  display: inline-block;
  position: relative;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease 0s;
  transition: opacity 0.4s ease 0s;
  min-width: 3px;
}

.u-split-span:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  -webkit-transition: opacity 0s ease 0s;
  transition: opacity 0s ease 0s;
  -webkit-transition: opacity 0s ease 0s, -webkit-transform 0s ease 0s;
  transition: opacity 0s ease 0s, -webkit-transform 0s ease 0s;
  transition: opacity 0s ease 0s, transform 0s ease 0s;
  transition: opacity 0s ease 0s, transform 0s ease 0s, -webkit-transform 0s ease 0s;
  transition: opacity 0s ease 0s, transform 0s ease 0s;
  border: 1px solid #fff;
  width: 100%;
  height: 100%;
  content: "";
}

.u-split-span:after {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  -webkit-transition: opacity 0s ease 0s;
  transition: opacity 0s ease 0s;
  background-color: #fff;
  width: 100%;
  height: 100%;
  content: "";
}

.u-split-span.js-active {
  opacity: 1;
  -webkit-transition-duration: 0s;
          transition-duration: 0s;
}

.u-split-span.js-active:before {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  opacity: 0;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.u-split-span.js-active:after {
  opacity: 0;
  -webkit-transition-duration: 0.1s;
          transition-duration: 0.1s;
}

.u-split-span--1 {
  -webkit-transition-delay: 0.02s;
          transition-delay: 0.02s;
}

.u-split-span--2 {
  -webkit-transition-delay: 0.04s;
          transition-delay: 0.04s;
}

.u-split-span--3 {
  -webkit-transition-delay: 0.06s;
          transition-delay: 0.06s;
}

.u-split-span--4 {
  -webkit-transition-delay: 0.08s;
          transition-delay: 0.08s;
}

.u-split-span--5 {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}

.u-split-span--6 {
  -webkit-transition-delay: 0.12s;
          transition-delay: 0.12s;
}

.u-split-span--7 {
  -webkit-transition-delay: 0.14s;
          transition-delay: 0.14s;
}

.u-split-span--8 {
  -webkit-transition-delay: 0.16s;
          transition-delay: 0.16s;
}

.u-split-span--9 {
  -webkit-transition-delay: 0.18s;
          transition-delay: 0.18s;
}

.u-split-span--10 {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}

.u-split-span--11 {
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
}

.u-split-span--12 {
  -webkit-transition-delay: 0.24s;
          transition-delay: 0.24s;
}

.u-split-span--13 {
  -webkit-transition-delay: 0.26s;
          transition-delay: 0.26s;
}

.u-split-span--14 {
  -webkit-transition-delay: 0.28s;
          transition-delay: 0.28s;
}

.u-split-span--15 {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}

.u-split-span--16 {
  -webkit-transition-delay: 0.32s;
          transition-delay: 0.32s;
}

.u-split-span--17 {
  -webkit-transition-delay: 0.34s;
          transition-delay: 0.34s;
}

.u-split-span--18 {
  -webkit-transition-delay: 0.36s;
          transition-delay: 0.36s;
}

.u-split-span--19 {
  -webkit-transition-delay: 0.38s;
          transition-delay: 0.38s;
}

.u-split-span--20 {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}

.u-split-span--21 {
  -webkit-transition-delay: 0.42s;
          transition-delay: 0.42s;
}

.u-split-span--22 {
  -webkit-transition-delay: 0.44s;
          transition-delay: 0.44s;
}

.u-split-span--23 {
  -webkit-transition-delay: 0.46s;
          transition-delay: 0.46s;
}

.u-split-span--24 {
  -webkit-transition-delay: 0.48s;
          transition-delay: 0.48s;
}

.u-split-span--25 {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}

.u-split-span--26 {
  -webkit-transition-delay: 0.52s;
          transition-delay: 0.52s;
}

.u-split-span--27 {
  -webkit-transition-delay: 0.54s;
          transition-delay: 0.54s;
}

.u-split-span--28 {
  -webkit-transition-delay: 0.56s;
          transition-delay: 0.56s;
}

.u-split-span--29 {
  -webkit-transition-delay: 0.58s;
          transition-delay: 0.58s;
}

.u-split-span--30 {
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
}

.u-split-span--31 {
  -webkit-transition-delay: 0.62s;
          transition-delay: 0.62s;
}

.u-split-span--32 {
  -webkit-transition-delay: 0.64s;
          transition-delay: 0.64s;
}

.u-split-span--33 {
  -webkit-transition-delay: 0.66s;
          transition-delay: 0.66s;
}

.u-split-span--34 {
  -webkit-transition-delay: 0.68s;
          transition-delay: 0.68s;
}

.u-split-span--35 {
  -webkit-transition-delay: 0.7s;
          transition-delay: 0.7s;
}

.u-split-span--36 {
  -webkit-transition-delay: 0.72s;
          transition-delay: 0.72s;
}

.u-split-span--37 {
  -webkit-transition-delay: 0.74s;
          transition-delay: 0.74s;
}

.u-split-span--38 {
  -webkit-transition-delay: 0.76s;
          transition-delay: 0.76s;
}

.u-split-span--39 {
  -webkit-transition-delay: 0.78s;
          transition-delay: 0.78s;
}

.u-split-span--40 {
  -webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
}

.u-split-span--41 {
  -webkit-transition-delay: 0.82s;
          transition-delay: 0.82s;
}

.u-split-span--42 {
  -webkit-transition-delay: 0.84s;
          transition-delay: 0.84s;
}

.u-split-span--43 {
  -webkit-transition-delay: 0.86s;
          transition-delay: 0.86s;
}

.u-split-span--44 {
  -webkit-transition-delay: 0.88s;
          transition-delay: 0.88s;
}

.u-split-span--45 {
  -webkit-transition-delay: 0.9s;
          transition-delay: 0.9s;
}

.u-split-span--46 {
  -webkit-transition-delay: 0.92s;
          transition-delay: 0.92s;
}

.u-split-span--47 {
  -webkit-transition-delay: 0.94s;
          transition-delay: 0.94s;
}

.u-split-span--48 {
  -webkit-transition-delay: 0.96s;
          transition-delay: 0.96s;
}

.u-split-span--49 {
  -webkit-transition-delay: 0.98s;
          transition-delay: 0.98s;
}

.u-split-span--50 {
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

.u-split-span--51 {
  -webkit-transition-delay: 1.02s;
          transition-delay: 1.02s;
}

.u-split-span--52 {
  -webkit-transition-delay: 1.04s;
          transition-delay: 1.04s;
}

.u-split-span--53 {
  -webkit-transition-delay: 1.06s;
          transition-delay: 1.06s;
}

.u-split-span--54 {
  -webkit-transition-delay: 1.08s;
          transition-delay: 1.08s;
}

.u-split-span--55 {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}

.u-split-span--56 {
  -webkit-transition-delay: 1.12s;
          transition-delay: 1.12s;
}

.u-split-span--57 {
  -webkit-transition-delay: 1.14s;
          transition-delay: 1.14s;
}

.u-split-span--58 {
  -webkit-transition-delay: 1.16s;
          transition-delay: 1.16s;
}

.u-split-span--59 {
  -webkit-transition-delay: 1.18s;
          transition-delay: 1.18s;
}

.u-split-span--60 {
  -webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}

.u-split-span--61 {
  -webkit-transition-delay: 1.22s;
          transition-delay: 1.22s;
}

.u-split-span--62 {
  -webkit-transition-delay: 1.24s;
          transition-delay: 1.24s;
}

.u-split-span--63 {
  -webkit-transition-delay: 1.26s;
          transition-delay: 1.26s;
}

.u-split-span--64 {
  -webkit-transition-delay: 1.28s;
          transition-delay: 1.28s;
}

.u-split-span--65 {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}

.u-split-span--66 {
  -webkit-transition-delay: 1.32s;
          transition-delay: 1.32s;
}

.u-split-span--67 {
  -webkit-transition-delay: 1.34s;
          transition-delay: 1.34s;
}

.u-split-span--68 {
  -webkit-transition-delay: 1.36s;
          transition-delay: 1.36s;
}

.u-split-span--69 {
  -webkit-transition-delay: 1.38s;
          transition-delay: 1.38s;
}

.u-split-span--70 {
  -webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}

.u-split-span--71 {
  -webkit-transition-delay: 1.42s;
          transition-delay: 1.42s;
}

.u-split-span--72 {
  -webkit-transition-delay: 1.44s;
          transition-delay: 1.44s;
}

.u-split-span--73 {
  -webkit-transition-delay: 1.46s;
          transition-delay: 1.46s;
}

.u-split-span--74 {
  -webkit-transition-delay: 1.48s;
          transition-delay: 1.48s;
}

.u-split-span--75 {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}

.u-split-span--76 {
  -webkit-transition-delay: 1.52s;
          transition-delay: 1.52s;
}

.u-split-span--77 {
  -webkit-transition-delay: 1.54s;
          transition-delay: 1.54s;
}

.u-split-span--78 {
  -webkit-transition-delay: 1.56s;
          transition-delay: 1.56s;
}

.u-split-span--79 {
  -webkit-transition-delay: 1.58s;
          transition-delay: 1.58s;
}

.u-split-span--80 {
  -webkit-transition-delay: 1.6s;
          transition-delay: 1.6s;
}

.u-split-span--81 {
  -webkit-transition-delay: 1.62s;
          transition-delay: 1.62s;
}

.u-split-span--82 {
  -webkit-transition-delay: 1.64s;
          transition-delay: 1.64s;
}

.u-split-span--83 {
  -webkit-transition-delay: 1.66s;
          transition-delay: 1.66s;
}

.u-split-span--84 {
  -webkit-transition-delay: 1.68s;
          transition-delay: 1.68s;
}

.u-split-span--85 {
  -webkit-transition-delay: 1.7s;
          transition-delay: 1.7s;
}

.u-split-span--86 {
  -webkit-transition-delay: 1.72s;
          transition-delay: 1.72s;
}

.u-split-span--87 {
  -webkit-transition-delay: 1.74s;
          transition-delay: 1.74s;
}

.u-split-span--88 {
  -webkit-transition-delay: 1.76s;
          transition-delay: 1.76s;
}

.u-split-span--89 {
  -webkit-transition-delay: 1.78s;
          transition-delay: 1.78s;
}

.u-split-span--90 {
  -webkit-transition-delay: 1.8s;
          transition-delay: 1.8s;
}

.u-split-span--91 {
  -webkit-transition-delay: 1.82s;
          transition-delay: 1.82s;
}

.u-split-span--92 {
  -webkit-transition-delay: 1.84s;
          transition-delay: 1.84s;
}

.u-split-span--93 {
  -webkit-transition-delay: 1.86s;
          transition-delay: 1.86s;
}

.u-split-span--94 {
  -webkit-transition-delay: 1.88s;
          transition-delay: 1.88s;
}

.u-split-span--95 {
  -webkit-transition-delay: 1.9s;
          transition-delay: 1.9s;
}

.u-split-span--96 {
  -webkit-transition-delay: 1.92s;
          transition-delay: 1.92s;
}

.u-split-span--97 {
  -webkit-transition-delay: 1.94s;
          transition-delay: 1.94s;
}

.u-split-span--98 {
  -webkit-transition-delay: 1.96s;
          transition-delay: 1.96s;
}

.u-split-span--99 {
  -webkit-transition-delay: 1.98s;
          transition-delay: 1.98s;
}

.u-split-span--100 {
  -webkit-transition-delay: 2s;
          transition-delay: 2s;
}

/* ========================================

    news
        newsページのみで使うスタイル

    INDEX
        - main

======================================== */
/* ========================================
    main
======================================== */
.news {
  color: white;
}

@media screen and (min-width: 769px) {
  .news {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1300px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .news .sub-title {
    text-align: right;
  }
}

@media screen and (max-width: 768px) {
  .news .sub-title:after {
    width: 75px;
    right: 170px;
  }
}

@media screen and (min-width: 769px) {
  .news .sub-title:after {
    top: 180px;
  }
}

.news__inner {
  max-width: 1100px;
  width: 100%;
}

.news__inner .category-list {
  max-width: 800px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .news__inner .category-list__item:nth-of-type(n + 4) {
    border-top: 1px solid white;
  }
  .news__inner .category-list__item:nth-of-type(4) {
    border-left: none;
  }
}

.news__item {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .news__item {
    margin-bottom: 40px;
  }
}

.news__date {
  font-family: 'Hind Vadodara', sans-serif;
  color: #b0a46a;
  font-size: 2rem;
}

.news__label {
  font-size: 1.5rem;
  border: 1px solid white;
  min-width: 110px;
  display: inline-block;
  text-align: center;
  line-height: 1.28;
  margin-left: 9px;
}

.news__title {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.28;
  padding: 21px 0 33px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .news__title {
    padding: 10px 0 15px;
    font-size: 1.8rem;
  }
}

.news__title:after {
  content: '';
  display: block;
  position: absolute;
  background: white;
  width: 240px;
  height: 3px;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .news__title:after {
    width: 200px;
  }
}

.pagination {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pagination li {
  margin: 10px;
  border: 1px solid black;
  width: 30px;
  text-align: center;
}

.pagination li a {
  display: block;
  width: 100%;
  height: 100%;
}

.pagination li.active {
  background-color: black;
  color: white;
}

.test-wrapper {
  min-height: 1000px;
}

/* ========================================

    disco
        discoページのみで使うスタイル

    INDEX
        - main

======================================== */
/* ========================================
    main
======================================== */
.disco {
  color: white;
}

@media screen and (min-width: 769px) {
  .disco {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1300px;
    margin: 0 auto;
    min-height: 570px;
  }
}

@media screen and (max-width: 768px) {
  .disco .sub-title {
    text-align: right;
  }
}

@media screen and (max-width: 768px) {
  .disco .sub-title:after {
    width: 75px;
    right: 282px;
  }
}

@media screen and (min-width: 769px) {
  .disco .sub-title:after {
    top: 410px;
  }
}

.disco__inner {
  max-width: 1100px;
  width: 100%;
}

.disco__inner .category-list {
  max-width: 400px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .disco__inner .category-list {
    margin-bottom: 30px;
  }
}

.disco__inner .category-list__item {
  width: 25%;
}

@media screen and (max-width: 768px) {
  .disco__inner .category-list__item {
    width: 50%;
  }
  .disco__inner .category-list__item:nth-of-type(n + 3) {
    border-top: 1px solid white;
  }
  .disco__inner .category-list__item:nth-of-type(3) {
    border-left: none;
  }
}

.disco-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .disco-list {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 20px;
  }
}

.disco-list__item {
  width: 30%;
  margin-bottom: 47px;
}

@media screen and (max-width: 768px) {
  .disco-list__item {
    width: 47.7%;
    margin-bottom: 19px;
  }
}

@media screen and (min-width: 769px) {
  .disco-list__item:not(:nth-child(3n)) {
    margin-right: 40px;
  }
}

@media screen and (max-width: 768px) {
  .disco-list__item:not(:nth-child(2n)) {
    margin-right: 15px;
  }
}

.disco-list__img {
  position: relative;
  overflow: hidden;
}

.disco-list__img .blur {
  position: relative;
  -webkit-filter: blur(7px);
          filter: blur(7px);
}

.disco-list__img .blur:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.disco-list__img .blur:after {
  content: '';
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.65;
  top: 0;
}

.disco-list__img .blur img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

.disco-list__img .blur.black:after {
  content: '';
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.65;
  top: 0;
}

.disco-list__img .blur.black:before {
  content: '';
  display: block;
  padding-top: 141.4634%;
}

.disco-list__img .img img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-height: 100%;
}

.disco-list__label {
  font-family: 'Hind Vadodara', sans-serif;
  color: white;
  font-size: 1.5rem;
  min-width: 110px;
  border: 1px solid white;
  display: inline-block;
  text-align: center;
  line-height: 1.28;
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .disco-list__label {
    margin-top: 10px;
  }
}

.disco-list__title {
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.6;
  position: relative;
  margin-top: 14px;
}

@media screen and (max-width: 768px) {
  .disco-list__title {
    margin-top: 10px;
  }
}

.disco-list__title:hover {
  text-decoration: underline;
}

@media screen and (min-width: 769px) {
  .disco__detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-flow: row-reverse;
            flex-flow: row-reverse;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 68px;
  }
}

.disco__detail--left {
  max-width: 420px;
  width: 100%;
  margin-right: 80px;
}

@media screen and (max-width: 768px) {
  .disco__detail--left {
    margin: 37px auto 40px;
    max-width: 250px;
  }
}

.disco__detail--left .img-title {
  margin: 12px 0 32px;
}

@media screen and (max-width: 768px) {
  .disco__detail--left .img-title {
    margin: 12px 0 22px;
  }
}

.disco__detail--right {
  max-width: 600px;
  width: 100%;
}

.disco__detail .disco__label {
  margin-top: 0;
}

.disco__overview {
  border-bottom: solid 1px white;
  padding-bottom: 32px;
  margin-bottom: 31px;
}

.disco__overview .inner-title {
  padding-left: 19px;
  position: relative;
  font-weight: bold;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .disco__overview .inner-title {
    padding-left: 11px;
  }
}

.disco__overview .inner-title:before {
  content: '';
  display: block;
  position: absolute;
  background: white;
  width: 10px;
  height: 5px;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .disco__overview .inner-title:before {
    height: 2.5px;
    width: 4.5px;
  }
}

.disco__overview .inner-title:not(:first-child) {
  margin-top: 26px;
}

@media screen and (max-width: 768px) {
  .disco__overview .inner-title:not(:first-child) {
    margin-top: 17px;
  }
}

.disco__label {
  font-family: 'Hind Vadodara', sans-serif;
  color: white;
  font-size: 1.5rem;
  min-width: 110px;
  border: 1px solid white;
  display: inline-block;
  text-align: center;
  line-height: 1.28;
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .disco__label {
    margin-top: 10px;
  }
}

.disco__title {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.28;
  padding: 21px 0 33px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .disco__title {
    padding: 10px 0 15px;
    font-size: 1.8rem;
  }
}

.disco__title:after {
  content: '';
  display: block;
  position: absolute;
  background: white;
  width: 240px;
  height: 3px;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .disco__title:after {
    width: 200px;
  }
}

/* ========================================

    error
        errorページのみで使うスタイル

    INDEX
        - main

======================================== */
/* ========================================
    main
======================================== */
.not-found {
  color: white;
}

@media screen and (min-width: 769px) {
  .not-found {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1300px;
    min-height: 600px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .not-found .sub-title {
    text-align: right;
  }
}

@media screen and (max-width: 768px) {
  .not-found .sub-title:after {
    width: 75px;
    right: 305px;
  }
}

@media screen and (min-width: 769px) {
  .not-found .sub-title:after {
    top: 450px;
  }
}

.not-found__inner {
  max-width: 1100px;
  width: 100%;
}

.not-found__inner .xxx-large {
  font-family: 'Hind Vadodara', sans-serif;
  font-size: 16rem;
  line-height: 1;
  max-width: 600px;
  margin: 0 auto;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, transparent), color-stop(60%, #191919));
  background: linear-gradient(transparent 60%, #191919 60%);
  margin-bottom: 100px;
}

@media screen and (min-width: 769px) {
  .not-found__inner .xxx-large {
    font-size: 35rem;
  }
}

@media screen and (max-width: 768px) {
  .not-found__inner .xxx-large {
    margin-bottom: 30px;
  }
}

.not-found__inner .category-list {
  max-width: 400px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .not-found__inner .category-list {
    margin-bottom: 30px;
  }
}

.not-found__inner .category-list__item {
  width: 25%;
}

@media screen and (max-width: 768px) {
  .not-found__inner .category-list__item {
    width: 50%;
  }
}

.xxx-large {
  color: white;
  background-color: #191919;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  position: relative;
}

.xxx-large:after {
  content: '404';
  position: absolute;
  left: 2px;
  top: 2px;
  clip: rect(0, 900px, 0, 0);
  background-color: #191919;
  color: white;
  animation: glitch 3s infinite alternate-reverse;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

@media screen and (max-width: 768px) {
  .xxx-large:after {
    left: 51%;
    top: 51%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

.xxx-large:before {
  content: '404';
  position: absolute;
  left: -2px;
  top: -2px;
  clip: rect(0, 900px, 0, 0);
  background-color: #191919;
  color: white;
  animation: glitch 3s infinite alternate-reverse;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

@media screen and (max-width: 768px) {
  .xxx-large:before {
    left: 51%;
    top: 51%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

@-webkit-keyframes glitch {
  0% {
    clip: rect(29px, 9999px, 57px, 0);
  }
  5% {
    clip: rect(200px, 9999px, 138px, 0);
  }
  10% {
    clip: rect(138px, 9999px, 60px, 0);
  }
  15% {
    clip: rect(90px, 9999px, 200px, 0);
  }
  20% {
    clip: rect(132px, 9999px, 20px, 0);
  }
  25% {
    clip: rect(77px, 9999px, 53px, 0);
  }
  30% {
    clip: rect(118px, 9999px, 86px, 0);
  }
  35% {
    clip: rect(107px, 9999px, 86px, 0);
  }
  40% {
    clip: rect(160px, 9999px, 59px, 0);
  }
  45% {
    clip: rect(47px, 9999px, 168px, 0);
  }
  50% {
    clip: rect(98px, 9999px, 109px, 0);
  }
  55% {
    clip: rect(148px, 9999px, 69px, 0);
  }
  60% {
    clip: rect(23px, 9999px, 27px, 0);
  }
  65% {
    clip: rect(64px, 9999px, 27px, 0);
  }
  70% {
    clip: rect(51px, 9999px, 192px, 0);
  }
  75% {
    clip: rect(74px, 9999px, 63px, 0);
  }
  80% {
    clip: rect(75px, 9999px, 68px, 0);
  }
  85% {
    clip: rect(170px, 9999px, 131px, 0);
  }
  90% {
    clip: rect(25px, 9999px, 180px, 0);
  }
  95% {
    clip: rect(64px, 9999px, 124px, 0);
  }
  100% {
    clip: rect(87px, 9999px, 82px, 0);
  }
}

@keyframes glitch {
  0% {
    clip: rect(29px, 9999px, 57px, 0);
  }
  5% {
    clip: rect(200px, 9999px, 138px, 0);
  }
  10% {
    clip: rect(138px, 9999px, 60px, 0);
  }
  15% {
    clip: rect(90px, 9999px, 200px, 0);
  }
  20% {
    clip: rect(132px, 9999px, 20px, 0);
  }
  25% {
    clip: rect(77px, 9999px, 53px, 0);
  }
  30% {
    clip: rect(118px, 9999px, 86px, 0);
  }
  35% {
    clip: rect(107px, 9999px, 86px, 0);
  }
  40% {
    clip: rect(160px, 9999px, 59px, 0);
  }
  45% {
    clip: rect(47px, 9999px, 168px, 0);
  }
  50% {
    clip: rect(98px, 9999px, 109px, 0);
  }
  55% {
    clip: rect(148px, 9999px, 69px, 0);
  }
  60% {
    clip: rect(23px, 9999px, 27px, 0);
  }
  65% {
    clip: rect(64px, 9999px, 27px, 0);
  }
  70% {
    clip: rect(51px, 9999px, 192px, 0);
  }
  75% {
    clip: rect(74px, 9999px, 63px, 0);
  }
  80% {
    clip: rect(75px, 9999px, 68px, 0);
  }
  85% {
    clip: rect(170px, 9999px, 131px, 0);
  }
  90% {
    clip: rect(25px, 9999px, 180px, 0);
  }
  95% {
    clip: rect(64px, 9999px, 124px, 0);
  }
  100% {
    clip: rect(87px, 9999px, 82px, 0);
  }
}
/*# sourceMappingURL=main.css.map */
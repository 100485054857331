/* ========================================

    disco
        discoページのみで使うスタイル

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.disco {
    color: $C_WHITE;
    @include mq-pc {
        display: flex;
        max-width: 1300px;
        margin: 0 auto;
        min-height: 570px;
    }

    .sub-title {
        @include mq-mb {
            text-align: right;
        }
        &:after {
            @include mq-mb {
                width: 75px;
                right: 282px;
            }
            @include mq-pc {
                top: 410px;
            }
        }
    }

    &__inner {
        max-width: 1100px;
        width: 100%;

        .category-list {
            max-width: 400px;
            width: 100%;
            @include mq-mb {
                margin-bottom: 30px;
            }

            &__item {
                width: 25%;
                @include mq-mb {
                    width: 50%;
                    &:nth-of-type(n + 3) {
                        border-top: 1px solid white;
                    }
                    &:nth-of-type(3) {
                        border-left: none;
                    }
                }
            }
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        @include mq-mb {
            justify-content: space-between;
            margin-bottom: 20px;
        }

        &__item {
            width: 30%;
            margin-bottom: 47px;
            @include mq-mb {
                width: 47.7%;
                margin-bottom: 19px;
            }
    
            &:not(:nth-child(3n)) {
                @include mq-pc {
                    margin-right: 40px;
                }
            }
            &:not(:nth-child(2n)) {
                @include mq-mb {
                    margin-right: 15px;
                }
            }
        }
    
        &__img {
            position: relative;
            overflow: hidden;
            .blur {
                position: relative;
                filter: blur(7px);
                &:before {
                    content: '';
                    display: block;
                    padding-top: 100%;
                }
                &:after {
                    content: '';
                    background-color: #fff;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    opacity: 0.65;
                    top: 0;
                }
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    object-fit: cover;
                }

                &.black {
                    &:after {
                        content: '';
                        background-color: #000;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        opacity: 0.65;
                        top: 0;
                    }
                    &:before {
                        content: '';
                        display: block;
                        padding-top: 141.4634%;
                    }
                } 
            }
            .img {
                img {
                    @include center;
                    max-height: 100%;
                }
            }
        }

        &__label {
            @include Vadodara;
            color: $C_WHITE;
            font-size: 1.5rem;
            min-width: 110px;
            border: 1px solid $C_WHITE;
            display: inline-block;
            text-align: center;
            line-height: 1.28;
            margin-top: 30px;
            @include mq-mb {
                margin-top: 10px;
            }
        }

        &__title {
            color: $C_WHITE;
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 1.6;
            position: relative;
            margin-top: 14px;
            @include mq-mb {
                margin-top: 10px;
            }
    
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__detail {
        @include mq-pc {
            display: flex;
            flex-flow: row-reverse;
            justify-content: space-between;
            margin-bottom: 68px;
        }

        &--left {
            max-width: 420px;
            width: 100%;
            margin-right: 80px;
            @include mq-mb {
                margin: 37px auto 40px;
                max-width: 250px;
            }

            .img-title {
                margin: 12px 0 32px;
                @include mq-mb {
                    margin: 12px 0 22px;
                }
            }
        }

        &--right {
            max-width: 600px;
            width: 100%;
        }

        .disco__label {
            margin-top: 0;
        }
    }

    &__overview {
        border-bottom: solid 1px $C_WHITE;
        padding-bottom: 32px;
        margin-bottom: 31px;

        .inner-title {
            padding-left: 19px;
            position: relative;
            font-weight: bold;
            font-size: 1.6rem;
            @include mq-mb {
                padding-left: 11px;
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                background: $C_WHITE;
                width: 10px;
                height: 5px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                @include mq-mb {
                    height: 2.5px;
                    width: 4.5px;
                }
            }
            &:not(:first-child) {
                margin-top: 26px;
                @include mq-mb {
                    margin-top: 17px;
                }
            }
        }
    }

    &__label {
        @include Vadodara;
        color: $C_WHITE;
        font-size: 1.5rem;
        min-width: 110px;
        border: 1px solid $C_WHITE;
        display: inline-block;
        text-align: center;
        line-height: 1.28;
        margin-top: 30px;
        @include mq-mb {
            margin-top: 10px;
        }
    }

    &__title {
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 1.28;
        padding: 21px 0 33px;
        position: relative;
        @include mq-mb {
            padding: 10px 0 15px;
            font-size: 1.8rem;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            background: $C_WHITE;
            width: 240px;
            height: 3px;
            bottom: 0;
            @include mq-mb {
                width: 200px;
            }
        }
    }
}
/* ========================================

    title
        見出しのパーツ

    INDEX
        - section
        - main
        - sub

======================================== */

/* ========================================
    global
        大見出し
======================================== */
.section-title {
    color: $C_WHITE;
    font-family: $ENG_FONT_FAMILY;
    font-weight: 700;
    font-size: 4.6vw;
    line-height: 1;
    letter-spacing: 0.04em;
    margin: 0 50px 80px 0;
    text-align: right;
    position: relative;

    @media screen and (min-width: 1400px) {
        font-size: 6.5rem;
        line-height: 1;
    }
    @include mq-l() {
        margin: 0 50px 40px 0;
        font-size: 4.5rem;
        line-height: 1;
    }
    @include mq-m() {
        margin: 0 20px 20px 0;
        font-size: 3.8rem;
        line-height: 1;
    }
    @include mq-s() {
        margin: 0 15px 20px 0;
        font-size: 3rem;
        line-height: 1;
    }

    &:after {
        content: attr(data-text)"";
        font-size: 7vw;
        line-height: 1;
        color: rgba(255, 255, 255, 0.1);
        position: absolute;
        right: -50px;
        bottom: 20px;

        @media screen and (min-width: 1400px) {
            font-size: 10rem;
            line-height: 1;
        }
        @include mq-l() {
            font-size: 6.5rem;
            line-height: 1;
        }
        @include mq-m() {
            font-size: 5.5rem;
            line-height: 1;
            right: -20px;
            bottom: 10px;
        }
        @include mq-s() {
            font-size: 5.5rem;
            line-height: 1;
            right: -15px;
            bottom: 10px;
        }
    }
}

/* ========================================
    main
        中見出し
======================================== */
.main-title {
    color: $C_WHITE;
    font-family: $ENG_FONT_FAMILY;
    font-weight: 700;
    font-size: 3.57vw;
    line-height: 1;
    padding-left: 50px;
    margin-bottom: 50px;
    position: relative;

    @media screen and (min-width: 1400px) {
        font-size: 5rem;
        line-height: 1;
    }
    @include mq-l() {
        font-size: 3.5rem;
        line-height: 1;
        margin-bottom: 30px;
    }
    @include mq-m() {
        font-size: 3.2rem;
        line-height: 1;
        padding-left: 20px;
        margin-bottom: 20px;
    }
    @include mq-s() {
        font-size: 2.8rem;
        line-height: 1;
        padding-left: 15px;
        margin-bottom: 20px;
    }

    &:after {
        content: '';
        display: block;
        width: 50%;
        height: 1px;
        background: $C_WHITE;
        position: absolute;
        left: 0;
        bottom: -2px;
    }

    &-jp {
        font-size: 2.86vw;
        line-height: 1;

        @media screen and (min-width: 1400px) {
            font-size: 4rem;
            line-height: 1;
        }
        @include mq-l() {
            font-size: 2.8rem;
            line-height: 1;
        }
        @include mq-m() {
            font-size: 2.5rem;
            line-height: 1;
        }
        @include mq-s() {
            font-size: 2.3rem;
            line-height: 1;
        }

        &:after {
            width: 65%;
        }
    }
}

/* ========================================
    sub
        子見出し
======================================== */
.sub-title {
    @include Vadodara;
    font-size: 6rem;
    color: $C_WHITE;
    position: relative;
    @include mq-pc {
        writing-mode: vertical-rl;
    }
    @include mq-mb {
        // display: inline-block;
        font-size: 3rem;
        margin-bottom: 10px;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        background: $C_WHITE;
        @include mq-pc {
            height: 145px;
            width: 3px;
            top: 25%;
            left: 42%;
            margin-left: 6px;
        }
        @include mq-mb {
            top: 0;
            bottom: 0;
            margin: auto;
            right: -14px;
            width: 90px;
            height: 1px;
            transform: translateX(100%);
        }
    }
}
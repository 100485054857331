/* ========================================
    
    header
        ヘッダーのパーツ

    INDEX
        - common

======================================== */


/* ========================================
    common
======================================== */
.header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    @include mq-header-menu {
        display: none;
    }
    &__inner {
        max-width: 1540px !important;
        margin: 0 auto;
        @media screen and (max-width: 1250px) {
            padding-left: 10px;
        }
    }

    &__nav {
        @include Vadodara;
        display: flex;
        margin-right: 53px;
        padding: 43px 0;
        justify-content: flex-end;
        font-size: 1.8rem;
        align-items: center;

        &-item {
            position: relative;
            cursor: pointer;
            text-align: center;
            
            &:not(:nth-last-child(-n + 2)):before {
                position: absolute;
                content: "";
                display: inline-block;
                background: $C_WHITE;
                width: 1px;
                height: 80%;
                top: 7px;
                right: 0;
            }

            &:not(:last-child) {
                padding: 0 15px;
            }
            &:last-child {
                padding-left: 10px;
            }
            .nav-btn {
                display: block;
                color: $C_WHITE;

                &:hover {
                    color: $C_BLACK;
                    background: $C_WHITE;
                }
                
                &-jp {
                    display: block;
                    font-size: 0.6em;
                    margin-top: -5px;
                }
            }

            &.archive {
                position: relative;
                .sub-menu {
                    display: none;
                    position: absolute;
                    top: 60px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 250px;
                    z-index: 9999;
                    background: $C_WHITE;
                    text-align: center;
                    a {
                        color: $C_BLACK!important;
                        display: block;
                        padding: 11px 0;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        top: -30px;
                        left: 50%;
                        margin-left: -15px;
                        border: 15px solid transparent;
                        border-bottom: 15px solid $C_WHITE;
                    }

                    &__item {
                        &:not(:last-child) {
                            border-bottom: solid 1px $C_BLACK;
                        }
                    }
                }
                &:hover {
                    .sub-menu {
                        display: block;
                    }
                }
            }
        }

    }
}

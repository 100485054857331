/* ========================================

    news
        newsページのみで使うスタイル

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.news {
    color: $C_WHITE;
    @include mq-pc {
        display: flex;
        max-width: 1300px;
        margin: 0 auto;
    }

    .sub-title {
        @include mq-mb {
            text-align: right;
        }
        &:after {
            @include mq-mb {
                width: 75px;
                right: 170px;
            }
            @include mq-pc {
                top: 180px;
            }
        }
    }

    &__inner {
        max-width: 1100px;
        width: 100%;

        .category-list {
            max-width: 800px;
            width: 100%;

            &__item {
                @include mq-mb {
                    &:nth-of-type(n + 4) {
                        border-top: 1px solid white;
                    }
                    &:nth-of-type(4) {
                        border-left: none;
                    }
                }
            }
        }
    }

    &__item {
        margin-bottom: 100px;
        @include mq-mb {
            margin-bottom: 40px;
        }
    }

    &__date {
        @include Vadodara;
        color: $C_DATE;
        font-size: 2rem;
    }

    &__label {
        font-size: 1.5rem;
        border: 1px solid $C_WHITE;
        min-width: 110px;
        display: inline-block;
        text-align: center;
        line-height: 1.28;
        margin-left: 9px;
    }

    &__title {
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 1.28;
        padding: 21px 0 33px;
        position: relative;
        @include mq-mb {
            padding: 10px 0 15px;
            font-size: 1.8rem;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            background: $C_WHITE;
            width: 240px;
            height: 3px;
            bottom: 0;
            @include mq-mb {
                width: 200px;
            }
        }
    }
}

.pagination {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    li {
        margin: 10px;
        border: 1px solid black;
        width: 30px;
        text-align: center;
        a {
        display: block;
        width: 100%;
        height: 100%;
        }
        &.active {
        background-color: black;
        color: white;
        }
    }
}


.test-wrapper {
    min-height: 1000px;
}

// .pager {
//     margin: 0 auto 150px;
//     color: white;
//     /* text-align: center; */
//     display: flex;
//     justify-content: center;
//     position: absolute;
//     bottom: 0;
//     left: 50%;
//     transform: translateX(-50%);
// }
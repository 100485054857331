/* ========================================
  
    list
        リストのスタイル

    INDEX
        - text list
        - thumb list
        - circle list

======================================== */

// ========================================
//  text list
//      テキストのみリスト
// ========================================
.list {
    display: block;
    margin-bottom: 100px;
    @include mq-mb {
        margin-bottom: 40px;
    }

    &__item {
        word-break: break-word;
        font-size: 16px;

        & + & {
            margin-top: 32px;
        }
    }

    &__link {
        color: white;
        display: block;
    }

    &__date {
        @include Vadodara;
        color: #b0a46a;
        font-size: 2rem;
        @include mq-mb {
            font-size: 14px;
        }
    }

    &__label {
        @include Vadodara;
        font-size: 15px;
        border: 1px solid white;
        min-width: 110px;
        display: inline-block;
        text-align: center;
        line-height: 1.28;
        margin-left: 9px;
        @include mq-mb {
            font-size: 11px;
            min-width: 83px;
        }
    }

    &__title {
        @include mq-mb {
            font-size: 12px;
        }
        &:hover {
            text-decoration: underline;
            text-decoration-color: $C_WHITE;
        }
        &.none {
            pointer-events: none;
        }
    }
}

.category-list {
    $this: &;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 47px;
    border: 1px solid white;
    @include mq-mb {
        margin-bottom: 25px;
    }

    &__item {
        @include Vadodara;
        width: (100% / 6);
        background-color: black;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        cursor: pointer;
        &.current {
            background-color: white;
            color: black;
        }
        &:not(.current) {
            border-left: 1px solid white;
        }
        @include mq-mb {
            width: 33.33333%;
            font-size: 11px;
            height: 30px;
        }

        &:hover {
            color: $C_BLACK;
            background: $C_WHITE;
        }
    }

    &__link {
        @include Vadodara;
        color: white;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;

        &:hover {
            color: $C_BLACK;
        }
    }
}

.list-none {
    @include mq-mb {
        text-align: center;
        margin-bottom: 40px;
    }
}

.fade {
    &-enter {
        opacity: 0;
        &-active {
            transition: opacity 1s;
        }
        &-t0 {
            opacity: 1;
        }
    }
}
.fade2 {
    &-enter {
        opacity: 0;
        &-active {
            transition: opacity 1s;
        }
        &-to {
            opacity: 1;
        }
    }
}
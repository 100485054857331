/* ========================================

    error
        errorページのみで使うスタイル

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.not-found {
    color: $C_WHITE;
    @include mq-pc {
        display: flex;
        max-width: 1300px;
        min-height: 600px;
        margin: 0 auto;
    }

    .sub-title {
        @include mq-mb {
            text-align: right;
        }
        &:after {
            @include mq-mb {
                width: 75px;
                right: 305px;
            }
            @include mq-pc {
                top: 450px;
            }
        }
    }

    &__inner {
        max-width: 1100px;
        width: 100%;

        .xxx-large {
            @include Vadodara;
            font-size: 16rem;
            line-height: 1;
            max-width: 600px;
            margin: 0 auto;
            background: linear-gradient(transparent 60%, #191919 60%);
            margin-bottom: 100px;
            @include mq-pc {
                font-size: 35rem;
            }
            @include mq-mb {
                margin-bottom: 30px;
            }
        }

        .category-list {
            max-width: 400px;
            width: 100%;
            @include mq-mb {
                margin-bottom: 30px;
            }

            &__item {
                width: 25%;
                @include mq-mb {
                    width: 50%;
                }
            }
        }
    }
}

.xxx-large {
    color: $C_WHITE;
    background-color: #191919;
    animation-delay: 2s;
    position: relative;
    &:after {
        content: '404';
        position: absolute;
        left: 2px;
        top: 2px;
        clip: rect(0, 900px, 0, 0); 
        background-color: #191919;
        color: $C_WHITE;
        animation: glitch 3s infinite alternate-reverse;
        animation-delay: 4s;
        @include mq-mb {
            left: 51%;
            top: 51%;
            transform: translate(-50%, -50%);
        }
    }
    &:before {
        content: '404';
        position: absolute;
        left: -2px;
        top: -2px;
        clip: rect(0, 900px, 0, 0); 
        background-color: #191919;
        color: $C_WHITE;
        animation: glitch 3s infinite alternate-reverse;
        animation-delay: 4s;
        @include mq-mb {
            left: 51%;
            top: 51%;
            transform: translate(-50%, -50%);
        }
    }
}

@keyframes glitch {
    $steps: 20;
    @for $i from 0 through $steps{
        #{percentage($i * (1 / $steps))}{
        clip: rect(random(200)+px, 9999px, random(200)+px, 0);
        }
    }
}
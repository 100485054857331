/* ========================================

    pager
        ページャーのパーツ

    INDEX
        - pager

======================================== */

/* ========================================
    pager
======================================== */
%pager-btn {
    @include Vadodara;
    border: 1px solid white;
    background-color: rgba(white, 0.2);
    color: white;
    padding: 10px 0;
    text-align: center;
    margin: 0 10px;
    @include mq-mb {
        padding: 0;
        font-size: 1.2rem;
        margin: 0 5px;
    }

    &:hover {
        color: $C_BLACK;
        background: $C_WHITE;
    }
}

.pager {
    margin: 0 auto 150px;
    color: $C_WHITE;
    text-align: center;
    display: flex;
    justify-content: center;
    @include mq-mb {
        margin: 0 auto 60px;
    }

    li {
        a {
            color:inherit;
            padding: 10px 20px;
            height: 50px;
            @include mq-mb {
                padding: 2px 10px;
            }
        }
    }

    // 戻る
    .btn-back {
        @extend %pager-btn;
        margin-right: 10px;
        position: relative;
        padding-left: 20px;
        @include mq-mb {
            margin-right: 5px;
            padding-left: 10px;
        }
        &:before {
            font-family: $FONT_AWESOME_FAMILY;
            content: '\f053';
            font-weight: 400;
            padding-right: 10px;
            position: absolute;
            @include mq-mb {
                left: 5px;
            }
        }
    }

    // 一覧へ
    .btn-list {
        @extend %pager-btn;
        margin: 0 5px;
    }
    
    // 次へ
    .btn-next {
        @extend %pager-btn;
        margin-left: 10px;
        position: relative;
        padding-right: 20px;
        @include mq-mb {
            margin-left: 5px;
            padding-right: 10px;
        }
        &:after {
            font-family: $FONT_AWESOME_FAMILY;
            content: '\f054';
            font-weight: 400;
            position: absolute;
        }
    }

    // 番号付きページング
    .btn-num {
        &__item {
            @extend %pager-btn;

            &.current {
                background: $C_WHITE;
                color: $C_BLACK!important;
            }
        }
    }

    // ボタンの打ち消し
    .disabled {
        pointer-events: none;
        opacity: 0.2;
    }
}

/* ========================================

    index
        indexページのみで使うスタイル

    INDEX
        - main

======================================== */

/* ========================================
    main
======================================== */
.hero {
    $hero: &;
    max-width: 1540px; // 左のpadding分多めに取っておく
    opacity: 0;
    @include mq-pc {
        position: relative;
        margin: 0 auto 60px;
    }
    @include mq-mb {
        margin: 0 auto 61px;
    }

    &__inner-logo {
        @include mq-pc {
            position: absolute;
            top: 0;
            left: 25px;
            width: 18%;
        }
        @include mq-mb {
            width: 100px;
            display: block;
            top: 0;
            left: 0;
        }
    }

    &__header {
        &--nav {
            width: 135px;
            font-size: 13px;
        }
        &--sns {
            font-size: 18px;
            color: white;
            padding: 0 7.5px;

            @include mq-pc {
                display: block;
                font-size: 24px;

                &:hover {
                    opacity: 0.8;
                }
            }

            &-wrap {
                position: absolute;
                right: 10px;
            }
        }
    }

    &__animate {
        display: flex;
        flex-direction: row-reverse;
        margin-right: 54px;
        @include mq-mb {
            display: block;
            margin: 0;
            padding-top: 16px;
        }
    }

    &__left {
        position: relative;
        flex: 1;
        z-index: 1;
        @include mq-mb {
            margin: 18px 0 0 20px;
        }
    }

    &__right {
        position: relative;
        width: 77%;
        max-width: 1000px;
        display: flex;
        justify-content: flex-end;
        z-index: 2;
        @include mq-mb {
            width: 100%;
            display: block;
        }
    }

    &__kv--logo {
        position: absolute;
        bottom: -25px;
        left: 30px;
        z-index: 2;
        pointer-events: none;

        @include mq-mb {
            top: 555px;
            left: 0;
        }
    }

    &__side-text {
        @include Vadodara;
        font-size: 1.04rem;
        display: block;
        bottom: 0;
        text-align: center;
        color: white;
        @include mq-pc {
            writing-mode: vertical-rl;
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(100%);
            line-height: 54px;
        }
        @include mq-mb {
            font-size: 2rem;
            padding: 0 17px;
            text-align: left;
            line-height: 1.2;
            margin-bottom: 15px;
        }
    }

    &__info {
        @include Vadodara;
        text-align: right;
        @include mq-pc {
            margin-top: 104%;
            padding-right: 2.8rem;
        }
        @media screen and (max-width: 1200px) {
            padding-right: 2rem;
        }
        @include mq-mb {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__info-text {
        @include mq-mb {
            flex: 1;
        }
    }

    &__logo {
        width: 215px;
        margin-left: auto;
        @include mq-mb {
            width: 100px;
            margin-bottom: auto;
        }
    }

    &__division-name {
        position: relative;
        @include mq-pc {
            margin-top: 21px;
            margin-bottom: 19px;
        }
        @include mq-mb {
            margin-bottom: 10px;
        }
        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            right: -30px;
            width: 1000px;
            height: 1px;
            @include mq-mb {
                right: -20px;
                width: 100%;
            }
        }
        h2 {
            font-size: 3.6rem;
            font-weight: bold;
            line-height: 1;
            padding: 5px 0 23px 0;
            @media screen and (max-width: 1505px) {
                font-size: 2.6rem;
            }
            @include mq-mb {
                line-height: 1;
                font-size: 1.98rem;
                padding: 0 0 15px 0;
            }
        }
    }

    &__affiliation-division {
        font-size: 2rem;
        line-height: 1;
        @media screen and (max-width: 1505px) {
            font-size: 1.5rem;
        }
        @include mq-mb {
            font-size: 15px;
        }
    }

    &__member-name {
        display: block;
        line-height: 1.4;
        font-size: 1.6rem;
        @media screen and (max-width: 1505px) {
            font-size: 1.1rem;
        }
        @include mq-mb {
            font-size: 12px;
        }
    }

    &.js-active {
        opacity: 1;
        transition: 0.3s;
    }

    // 色分けはここでやる
    &.division {
        &--oosaka {
            color: $C_OOSAKA;
            #{$hero}__division-name {
                &::before {
                    background-color: $C_OOSAKA;
                }
            }
        }
        &--nagoya {
            color: $C_NAGOYA;
            #{$hero}__division-name {
                &::before {
                    background-color: $C_NAGOYA;
                }
            }
        }
        &--shibuya {
            color: $C_SHIBUYA;
            #{$hero}__division-name {
                &::before {
                    background-color: $C_SHIBUYA;
                }
            }
        }
        &--shinjuku {
            color: $C_JINJUKU;
            #{$hero}__division-name {
                &::before {
                    background-color: $C_JINJUKU;
                }
            }
        }
        &--asakusa {
            color: $C_ASAKUSA;
            #{$hero}__division-name {
                &::before {
                    background-color: $C_ASAKUSA;
                }
            }
        }
        &--ikebukuro {
            color: $C_IKEBUKURO;
            #{$hero}__division-name {
                &::before {
                    background-color: $C_IKEBUKURO;
                }
            }
        }
        &--yokohama {
            color: $C_YOKOHAMA;
            #{$hero}__division-name {
                &::before {
                    background-color: $C_YOKOHAMA;
                }
            }
        }
        &--akabane {
            color: $C_AKABANE;
            #{$hero}__division-name {
                &::before {
                    background-color: $C_AKABANE;
                }
            }
        }
    }

}

.top-contents {
    max-width: 1400px;
    margin: 0 auto;
    @include mq-pc {
        display: flex;
        justify-content: space-between;
    }
    @include mq-mb {
        margin: 0 20px;
    }

    .news {
        color: $C_WHITE;
        @include mq-pc {
            display: flex;
            max-width: 800px;
            width: 100%;
        }

        .sub-title {
            @include mq-mb {
                display: inline-block;
            }
            &:after {
                @include mq-mb {
                    right: -14px;
                    width: 90px;
                }
            }
        }
    }
}


.twitter {
    @include mq-pc {
        display: flex;
        width: 450px;
    }

    .sub-title {
        @include mq-mb {
            display: inline-block;
        }
        &:after {
            @include mq-pc {
                top: 310px;
            }
        }
    }

    &__timeline {
        height: 700px;
        @include mq-mb {
            height: 400px;
        }
    }
}

.link-banner {
    max-width: 1200px;
    @include mq-pc {
        margin: 150px auto 50px;
        display: flex;
        justify-content: space-between;
    }
    @include mq-mb {
        margin: 50px 20px;
        text-align: center;
    }

    img {
        width: 100%;
        @include mq-pc {
            &:nth-child(2) {
                margin: 0 30px;
            }
        }
        @include mq-mb {
            margin-top: 16px;
        }
    }
}
/* ========================================
    banner
======================================== */
.banner {
	position: relative;
	overflow: hidden;
    // height: 150px;
    height: 238px;
    margin-top: 140px;
    @include mq-mb {
        margin-top: 100px;
    }

    &__wrap {
        // height: 150px;
        height: 238px;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__slider {
        display: flex;

        &-item {
            width: 337px;
            height: 238px;

            &:hover {
                opacity: 0.8;
            }
            @include mq-mb {
                width: 205px;
            }
        }

        &-img {
            width: 337px;
            @include mq-mb {
                width: 205px;
            }
        }
    }
}

/* ========================================
    slider
======================================== */
.slick-track {
    @include mq-mb {
        padding-bottom: 60px;
    }
}

.slick-slide {
    width: 337px !important;

    @include mq-mb {
        width: 205px !important;
    }

    img {
        width: 100%;
        margin: 0 auto;
    }
}

/* ========================================
    NOTICE
======================================== */
.notice {
    max-width: 1540px;
    width: 100%;
    padding-left:20px;
    padding-right: 54px;
    margin: 0 auto 70px;

    @include mq-mb {
        padding: 0 20px;
    }

    &__wrap {
        max-width: 1200px;
        width: 100%;
        min-height: 50px;
        background: black;
        border: 1px solid white;
        margin-right: 0;
        margin-left: auto;

        @include mq-mb {
            min-height: 70px;
        }
    }

    &__box {
        display: flex;
        align-items: center;
    }

    &__title {
        width: 180px;
        color: white;
        font-size: 20px;
        font-weight: bold;
        font-family: $ENG_FONT_FAMILY;
        text-align: center;

        @include mq-mb {
            font-size: 13px;
            min-width: 70px;
        }
    }

    &__news {
        display: flex;
        align-items: center;
        padding: 10px 29px;
        border-left: 1px solid white;

        @include mq-mb {
            display: block;
            padding: 18px 14px;
            line-height: 1em;
        }

        &--date {
            color: #B0A46A;
            font-size: 18px;
            font-weight: bold;
            font-family: $ENG_FONT_FAMILY;
            margin-right: 10px;

            @include mq-mb {
                font-size: 13px;
            }
        }

        &--link {
            font-size: 16px;
            color: white;

            @include mq-mb {
                font-size: 11px;
            }
        }
    }
}



/* ========================================
    swiper
======================================== */
.swiper-slide {
    padding-bottom: 30px;

    @include mq-mb {
        padding-left: 40px;
        text-align: center;
    }
}
.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    border-radius: 0;
    background: white;
    margin-left: 10px !important;

    @include mq-mb {
        display: block;
        margin-left: 15px !important;
        margin-bottom: 6px !important;
        width: 11px;
        height: 11px;
    }
}
.swiper-pagination-bullets {
    text-align: right;
    bottom: 0 !important;

    @include mq-mb {
        top: 0;
        max-width: 40px;
    }
}


/* ========================================
    fade
======================================== */
.js-fade {
    position: absolute;
    top: 0;
    left: 0;
}

.nodisplay {
    position: relative;
    opacity: 1;
    z-index: 2;
    transition: 0.4s 1s;

    &.js-active {
        opacity: 0;
        transition: 1s;
    }
}

.js-fade-wrap {
    position: relative;
    overflow: hidden;
}

.js-fade-logo {
    opacity: 0;
    transition: 0.3s;

    &.js-active {
        opacity: 1;
        transition: 0.6s;
    }
}

/* ========================================
    タイピングアニメーション
======================================== */
.u-split-span {
    display: inline-block;
    position: relative;
    opacity: 0;
    transition: opacity 0.4s ease 0s;
    min-width: 3px;
    &:before {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        transform: scale(1);
        opacity: 1;
        transition: opacity 0s ease 0s;
        transition: opacity 0s ease 0s, transform 0s ease 0s;
        transition: opacity 0s ease 0s, transform 0s ease 0s;
        border: 1px solid #fff;
        width: 100%;
        height: 100%;
        content: "";
    }
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        transition: opacity 0s ease 0s;
        background-color: #fff;
        width: 100%;
        height: 100%;
        content: "";
    }

    &.js-active {
        opacity: 1;
        transition-duration: 0s;

        &:before {
            transform: scale(1.5);
            opacity: 0;
            transition-duration: 0.3s;
        }

        &:after {
            opacity: 0;
            transition-duration: 0.1s;
        }
    }
}

@for $i from 1 through 100 {
    .u-split-span--#{$i} {
        transition-delay: $i * 0.02s;
    }
}
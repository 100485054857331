/* ========================================

    footer
        フッターのパーツ

    INDEX
        - common

======================================== */

/* ========================================
    common
======================================== */
.footer {
    background: $C_BLACK;
    color: $C_WHITE;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;

    .sns {
        @include Vadodara;
        display: flex;
        padding: 70px 0 28px;
        max-width: 215px;
        margin: 0 auto;
        @include mq-mb {
            padding: 33px 0 14px;
            max-width: 160px;
        }

        &__text {
            position: relative;
            font-size: 2.6rem;
            margin-right: 40px;
            @include mq-mb {
                font-size: 1.6rem;
            }
            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: -33px;
                margin: auto;
                width: 20px;
                height: 1px;
                background-color: white;
            }
        }

        &__icon {
            color: white;
            font-size: 26px;
            margin: 0 5px;
            @include mq-mb {
                font-size: 17px;
            }

            svg{
                width: 26px;
                @include mq-mb {
                    width: 17px;
                }
            }
        }
    }

    .copy {
        padding-bottom: 135px;
        padding-top: 20px;
        @include mq-mb {
            padding-top: 10px;
            padding-bottom: 100px;
            font-size: 10px;
        }
    }
}

/* ========================================

    post entry
        管理画面からの登録した本文の見た目調整用

======================================== */
.aem-post,
.post-entry {
    overflow: hidden;
    word-wrap: break-word;
    font-size: 1.4rem;
    line-height: 1.85;
    margin-top: 60px;
    @include mq-mb {
        margin-top: 45px;
    }

    strong {
        font-weight: bold;
    }

    img {
        max-width: 100%;
        height: auto !important;
    }

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.8em;
    }

    h3 {
        font-size: 1.6em;
    }

    h4 {
        font-size: 1.4em;
    }

    h5 {
        font-size: 1.2em;
    }

    h6 {
        font-size: 1em;
    }

    table,
    iframe {
        max-width: 100%;
    }

    table {
        width: 100%;
        table-layout: fixed;
        word-break: break-word;
    }

    a {
        color: $C_WHITE;
        text-decoration: underline;
    }

    em {
        font-style: italic;
    }
}

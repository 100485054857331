/* ========================================

    btn
        ボタンのスタイル

    INDEX
        - class
        - normal
        - special

======================================== */

// ========================================
//  class
// ========================================
// 一番普通のボタンの共通スタイル
%normal-btn {
    @include Vadodara;
    font-size: 20px;
    border: 1px solid white;
    background-color: rgba(white, 0.2);
    color: white;
    text-align: center; 
    width: 300px;
    margin: 50px auto;
    padding: 15px 0;
    text-decoration: none!important;
    font-weight: bold;

    @include mq-mb {
        width: 300px;
        min-height: 43px;
        font-size: 18px;
    }

    &:hover {
        color: $C_BLACK;
        background-color: $C_WHITE;
    }
}

// 目立たせたいボタンの共通スタイル
%special-btn {
    background-color: red;
    color: white;
}

// 幅いっぱいのサイズ
%btn-100 {
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

// ２つ並べられるサイズ
%btn-50 {
    display: inline-block;
    width: 49%;
}

// ちっちゃいサイズ
%btn-30 {
    display: block;
    width: 30%;
}


/* ========================================
    normal
        よく使うボタン
======================================== */
.btn-100 {
    @extend %normal-btn;
    @extend %btn-100;
}

.btn-50 {
    @extend %normal-btn;
    @extend %btn-50;
}

.btn-30 {
    $this: &;
    @extend %normal-btn;
    @extend %btn-30;
    margin: auto;

    // 右寄せ
    &--right {
        @extend #{$this};
        margin-right: 0;
        margin-left: auto;
    }

    // 左寄せ
    &--left {
        @extend #{$this};
        margin-right: auto;
        margin-left: 0;
    }
}


/* ========================================
    special
        特別目立たせたいボタン
======================================== */
.btn-special-100 {
    @extend %special-btn;
    @extend %btn-100;
}

.btn-special-50 {
    @extend %special-btn;
    @extend %btn-50;
}

.ghost-btn {
    display: block;
    border: 1px solid white;
    background-color: rgba(white, 0.2);
    color: white;
    padding: 4px 0;
    text-align: center;

    &--min {
        min-width: 158px;
    }
}

.btn-more {
    @include Vadodara;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    text-align: center;
    width: 300px;
    min-height: 67px;
    margin: 50px auto;
    @include mq-mb {
        width: 300px;
        min-height: 43px;
        font-size: 18px;
    }
}